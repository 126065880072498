import { Alert, Snackbar } from "@mui/material"
import { createContext, useState } from "react"


export const SnackbarContext = createContext()

export const SnackbarProvider = ({children}) => {
    const [ message, setMessage ] = useState("")
    const [ severity, setSeverity ] = useState("info")
    const [ open, setOpen ] = useState(false)
    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)
    const showMessage = (message, severity) => {
        setMessage(message)
        setSeverity(severity)
        handleOpen()
    }

    const state = {
        showMessage,
    }

    return (
        <SnackbarContext.Provider value={state}>
            {children}
            <Snackbar 
                autoHideDuration={6000}
                onClose={handleClose}
                open={open}>
                <Alert severity={severity}>{message}</Alert>
            </Snackbar>
        </SnackbarContext.Provider>
    )
}