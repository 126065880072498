import { BrowserRouter, Route, Routes } from "react-router-dom";
import { DashboardPage } from "./pages/Dashboard";
import { NavbarComponent } from "./components/Navbar";
import { FooterComponent } from "./components/Footer";
import { MsalProvider } from "@azure/msal-react";
import { UserManagePage } from "./pages/admin/UserPage";
import { AnalyticsPage } from "./pages/admin/AnalyticsPage";
import { SnackbarProvider } from "./context/SnackbarContext";
import { SecurityPage } from "./pages/admin/SecurityPage";
import { TimesheetPage } from "./pages/default/Timesheet";
import { QRGeneratorPage } from "./pages/tools/QRGenerator";
import { TicketingPage } from "./pages/default/Tickets";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { InventoryPage } from "./pages/admin/InventoryPage";
import { ClientAdminPage } from "./pages/admin/ClientPage";
import { ReportsPage } from "./pages/admin/ReportsPage";
import { LogsPage } from "./pages/admin/LogsPage";
import { PasswordCheckPage } from "./pages/tools/PasswordCheck";
import { IPLookupPage } from "./pages/tools/IPLookup";
import { DomainLookupPage } from "./pages/tools/DomainLookup";
import { PhoneLookupPage } from "./pages/tools/PhoneLookup";
import { WSSBufferedProvider } from "./context/NewWSSContext";
import { FileSharingPage } from "./pages/tools/FileSharing";
import { MinivanAIPage } from "./pages/tools/MinivanAI";

function App({pca}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <MsalProvider instance={pca}>
      <BrowserRouter>
        <SnackbarProvider>
          { /** WSS Test Provider */}
          <WSSBufferedProvider pca={pca}>
          { /*<WSSProvider pca={pca}> */}

            <NavbarComponent />
            <Routes>
              { /* Base Application Routes */}
              <Route path="/" element={<DashboardPage />} />
              <Route path="/timesheet" element={<TimesheetPage />} />
              <Route path="/ticketing" element={<TicketingPage />} />

              { /* Tools */ }
              <Route path="/tools/filesharing" element={<FileSharingPage />} />
              <Route path="/tools/qrgenerator" element={<QRGeneratorPage />} />
              <Route path="/tools/password" element={<PasswordCheckPage />} />
              <Route path="/tools/iplookup" element={<IPLookupPage />} />
              <Route path="/tools/domainlookup" element={<DomainLookupPage />} />
              <Route path="/tools/phonelookup" element={<PhoneLookupPage />} />
              <Route path="/tools/minivan-ai" element={<MinivanAIPage />} />

              { /* Admin Capable Routes */}
              <Route path="/admin/security" element={<SecurityPage />} />
              <Route path="/admin/users" element={<UserManagePage />} />
              <Route path="/admin/analytics" element={<AnalyticsPage />} />
              <Route path="/admin/inventory" element={<InventoryPage />} />
              <Route path="/admin/clients" element={<ClientAdminPage />} />
              <Route path="/admin/reports" element={<ReportsPage />} />
              <Route path="/admin/logs" element={<LogsPage />} />

            </Routes>
            <FooterComponent />

          { /* </WSSProvider> */ }
          </WSSBufferedProvider>
          {/* end </WSSProvider> */}
        </SnackbarProvider>
      </BrowserRouter>
    </MsalProvider>
    </LocalizationProvider>
  );
}

export default App;
