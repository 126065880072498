import { LogLevel } from "@azure/msal-browser";
import { AppConstants } from "../constants/AppConstants";


/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig = {
    auth: {
        clientId: AppConstants.ClientId, // This is the ONLY mandatory field that you need to supply.
        authority: `https://login.microsoftonline.com/${AppConstants.TenantId}`, // Choose SUSI as your default authority.
        redirectUri: AppConstants.RedirectURI, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        //console.info(message);
                        return;
                    case LogLevel.Verbose:
                        //console.debug(message);
                        return;
                    case LogLevel.Warning:
                        //console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};


export const loginRequest = {
    scopes: ["offline_access", "openid"],
    forceRefresh: true,
};