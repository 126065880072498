import { useMsal } from "@azure/msal-react";
import { Button, ListItem, ListItemButton } from "@mui/material";
import { loginRequest } from "../auth/authConfig";
import { WSSBufferedContext } from "./NewWSSContext";
import { useContext } from "react";
import LogoutIcon from '@mui/icons-material/Logout';

export const LoginPage = () => {
    return (
        <div className="loginRequired">
            <div style={{padding: '20px'}}>
                <img src={process.env.PUBLIC_URL + '/images/nsg-logo.webp'} alt="NSG" style={{maxWidth: '100%'}}/>
            </div>
            <LoginButton />
        </div>
    )
}


// Login Button
export const LoginButton = ({listItem, closeCb }) => {
    const { instance } = useMsal();
    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            instance.loginPopup({
                ...loginRequest,
                redirectUri: "/", // e.g. /redirect
            });
            if (closeCb !== undefined) {
                closeCb()
            }
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest);
            if (closeCb !== undefined) {
                closeCb()
            }
        }
    }

    // List Item Mobile Rendering
    if (listItem) {
        return (
            <ListItem className='drawerItem'>
                <ListItemButton onClick={() => handleLogin("redirect")}>
                    Login
                </ListItemButton>
            </ListItem>
        )
    } else {
        return (
            <Button className='navbarItem' color="inherit" variant='outlined' onClick={() => handleLogin("redirect")} 
                style={{marginLeft: '5px', marginRight: '5px'}}>Login</Button>
        )
    }
}

// Render Admin Capable
export const RenderAdmin = ({children}) => {
    return (
        <WSSBufferedContext.Consumer>
            {({reducerState}) => {
                if (reducerState.isAdmin) {
                    return <>{children}</>
                } else {
                    return <></>
                }
            }}
        </WSSBufferedContext.Consumer>
    )
}

// Render Application Group
export const RenderUser = ({children}) => {
    return (
        <WSSBufferedContext.Consumer>
            {({reducerState}) => {
                if (reducerState.isUser) {
                    return <>{children}</>
                } else {
                    return <></>
                }
            }}
        </WSSBufferedContext.Consumer>
    )
}

// Logout button
export const LogoutButton = ({listItem, closeCb }) => {
    const { instance } = useMsal();
    const handleLogout = () => {
        instance.logoutRedirect()
        if (closeCb !== undefined) {
            closeCb()
        }
    }
    if (listItem) {
        return (
            <ListItem className='drawerItem' style={{marginBottom: '100px'}}>
                <ListItemButton onClick={handleLogout} style={{backgroundColor: '#000', color: '#fff'}}>
                    <LogoutIcon />&nbsp;&nbsp;
                    Logout
                </ListItemButton>
            </ListItem>
        )
    } else {
        return (
            <Button className='navbarItem' color="inherit" variant='outlined' onClick={handleLogout} style={{marginLeft: '5px', marginRight: '5px'}}>Logout</Button>
        )
    }
}

// account bar up top 
export const AccountDetailBar = () => {
    const wssC = useContext(WSSBufferedContext)

    // auth bar 
    return (
        <div className="accountBar">
            <div><b>User:&nbsp;</b> { wssC.reducerState.accountDetails?.username}</div>
        </div>
    )
}