import { Button, Container, List, ListItem, ListSubheader, TextField } from "@mui/material"
import { useContext,  useEffect,  useRef,  useState } from "react"
import { Puff } from "react-loading-icons"
import { SnackbarContext } from "../../context/SnackbarContext"
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer } from "react-leaflet"
import { WSSBufferedContext } from "../../context/NewWSSContext";

export const IPLookupPage = () => {
    const [ ipAddress, setIpAddress ] = useState("")
    const [ loading, setLoading ] = useState(false)
    const handleChangeIpAddress = (e) => setIpAddress(e.target.value)

    const [ ipInfo, setIpInfo ] = useState({})
    const wssC = useContext(WSSBufferedContext)
    const sbC = useRef(useContext(SnackbarContext))
    const searchIpInfo = () => {
        // send wss message
        setLoading(true)
        wssC.sendWssMessage({"action":"ipLookup", "ipAddress": ipAddress}, (res) => {
            setMapPosition([res.latitude, res.longitude])
            setIpInfo(res)
            setLoading(false)
            sbC.current.showMessage("IP Information Received", "success")
        })
    }

    // enter key 
    const onKeyPress = (e) => {
        if (e.keyCode === 13) {
            searchIpInfo()
        }
    }

    const mapRef = useRef()
    const [ mapPosition, setMapPosition] = useState([0,0])
    useEffect(() => {
        if (mapPosition.length > 0) {
            if (mapRef.current === null) return 
            mapRef.current.flyTo(mapPosition, 8, {
                duration: 2
            });
        }
    }, [mapPosition])


    const listStyle = { style: {backgroundColor: '#000', color: '#fff', borderBottom: '1px solid #333'} }

    return (
        <div className="homePageClass">
            <Container>
                <h1 className="userHeader" style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{maxHeight: '25px'}}>
                        { loading && (
                            <Puff />
                        )}
                    </div>
                    IP Lookup
                </h1>

                <div style={{
                    display: 'flex',
                }}>
                    <TextField 
                        onKeyDown={onKeyPress}
                        sx={{
                            backgroundColor: '#000',
                            color: '#fff',
                            "& .MuiFormLabel-root": {
                                color: '#ccc'
                            }
                        }}
                        inputProps={{
                            style: {
                               color: '#fff'
                            }
                        }}
                        variant="filled"
                        value={ipAddress} onChange={handleChangeIpAddress} label="IP Address" fullWidth />
                    <Button variant="contained" onClick={searchIpInfo}>Search</Button>
                </div>

                { /* Ip Information */}
                { Object.keys(ipInfo) !== 0 && (
                    <div>
                        { /* GEO Map */}
                        <div>
                            <MapContainer center={[39, -98]} zoom={5} style={{minHeight: '300px', width: '100%'}} ref={mapRef}>
                                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"  />
                            </MapContainer>
                        </div>
                        <List style={{marginTop: '25px'}}>
                            <ListSubheader style={{backgroundColor: '#111', color: '#fff'}}>IP Information</ListSubheader>
                            <ListItem {...listStyle}><b>IP Address:&nbsp;</b> {ipInfo.ip}</ListItem>
                            <ListItem {...listStyle}><b>Version:&nbsp;</b> {ipInfo.version}</ListItem>
                            <ListItem {...listStyle}><b>City:&nbsp;</b> {ipInfo.city}</ListItem>
                            <ListItem {...listStyle}><b>Region:&nbsp;</b> {ipInfo.region}</ListItem>
                            <ListItem {...listStyle}><b>Country:&nbsp;</b> {ipInfo.country_name}</ListItem>
                            <ListItem {...listStyle}><b>Organization:&nbsp;</b> {ipInfo.org}</ListItem>
                            <ListItem {...listStyle}><b>Latitude:&nbsp;</b> {ipInfo.latitude}</ListItem>
                            <ListItem {...listStyle}><b>Longitude:&nbsp;</b> {ipInfo.longitude}</ListItem>
                        </List>
                    </div>
                )}
            </Container>
        </div>
    )
}