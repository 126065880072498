import { Button, Container, List, ListItem, ListSubheader, TextField } from "@mui/material"
import { useContext,  useEffect,  useRef,  useState } from "react"
import { Puff } from "react-loading-icons"
import { SnackbarContext } from "../../context/SnackbarContext"
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer } from "react-leaflet"
import { WSSBufferedContext } from "../../context/NewWSSContext";

export const DomainLookupPage = () => {
    const [ domain, setDomain ] = useState("")
    const [ loading, setLoading ] = useState(false)
    const handleChangeDomain = (e) => setDomain(e.target.value)

    const [ domainInfo, setDomainInfo ] = useState({})
    const [ whoisData, setWhoisData ] = useState("")
    const sbC = useRef(useContext(SnackbarContext))
    const wssC = useContext(WSSBufferedContext)
    const searchDomainInfo = () => {
        // clean domain 
        var tempDomain = domain;
        if (!tempDomain.startsWith("http://") && !tempDomain.startsWith("https://")) {
            tempDomain = 'https://' + tempDomain;
        } 

        // get clean 
        let a = document.createElement('a')
        a.href = tempDomain;
        let cleanDomain = a.hostname;

        setDomain(cleanDomain)

        // send wss message
        setLoading(true)
        wssC.sendWssMessage({"action":"domainLookup", "domain": cleanDomain}, (res) => {
            setDomainInfo(res)
            setMapPosition([res.lat, res.lng])
            sbC.current.showMessage("Domain Information Received", "success")
            setLoading(false)

            // set whois data
            if (res.whois !== "") {
                setWhoisData(atob(res.whois))
            }
        })
    }

    // 
    const onKeyPress = (e) => {
        if (e.keyCode === 13) {
            searchDomainInfo()
        }
    }

    const mapRef = useRef()
    const [ mapPosition, setMapPosition] = useState([0,0])
    useEffect(() => {
        if (mapPosition.length > 0) {
            if (mapRef.current === null) return 
            mapRef.current.flyTo(mapPosition, 8, {
                duration: 2
            });
        }
    }, [mapPosition])


    const listStyle = { style: {backgroundColor: '#000', color: '#fff', borderBottom: '1px solid #333'} }

    return (
        <div className="homePageClass">
            <Container>
                <h1 className="userHeader" style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{maxHeight: '25px'}}>
                        { loading && (
                            <Puff />
                        )}
                    </div>
                    Domain Lookup
                </h1>

                <div style={{
                    display: 'flex',
                }}>
                    <TextField 
                        onKeyDown={onKeyPress}
                        sx={{
                            backgroundColor: '#000',
                            color: '#fff',
                            "& .MuiFormLabel-root": {
                                color: '#ccc'
                            }
                        }}
                        inputProps={{
                            style: {
                               color: '#fff'
                            }
                        }}
                        variant="filled"
                        value={domain} onChange={handleChangeDomain} label="Domain" fullWidth />
                    <Button variant="contained" onClick={searchDomainInfo}>Search</Button>
                </div>

                { /* Ip Information */}
                { Object.keys(domainInfo) !== 0 && (
                    <div>
                        { /* GEO Map */}
                        <div>
                            <MapContainer center={[39, -98]} zoom={5} style={{minHeight: '300px', width: '100%'}} ref={mapRef}>
                                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"  />
                            </MapContainer>
                        </div>
                        { Object.keys(domainInfo).length !== 0 && (
                            <List style={{marginTop: '25px'}}>
                                <ListSubheader style={{backgroundColor: '#111', color: '#fff', textAlign: 'center'}}>CNAME Record</ListSubheader>
                                <ListItem {...listStyle}>{ domainInfo["CNAME"] }</ListItem>

                                { domainInfo["A_RECORD"] !== null && domainInfo["A_RECORD"].length !== 0 && (
                                    <div>
                                        <ListSubheader style={{backgroundColor: '#111', color: '#fff', textAlign: 'center'}}>A Records</ListSubheader>
                                        { domainInfo["A_RECORD"].map(item => (
                                            <ListItem {...listStyle} key={item}>{ item }</ListItem>
                                        ))}
                                    </div>
                                )}

                                { domainInfo["MX_RECORD"] !== null && domainInfo["MX_RECORD"].length !== 0 && (
                                    <div>
                                        <ListSubheader style={{backgroundColor: '#111', color: '#fff', textAlign: 'center'}}>MX Records</ListSubheader>
                                        { domainInfo["MX_RECORD"].map(item => (
                                            <ListItem {...listStyle} key={item}>{ item }</ListItem>
                                        ))}
                                    </div>
                                )}

                                { domainInfo["NS_RECORD"] !== null && domainInfo["NS_RECORD"].length !== 0 && (
                                    <div>
                                        <ListSubheader style={{backgroundColor: '#111', color: '#fff', textAlign: 'center'}}>NS Records</ListSubheader>
                                        { domainInfo["NS_RECORD"].map(item => (
                                            <ListItem {...listStyle} key={item}>{ item }</ListItem>
                                        ))}
                                    </div>
                                )}

                                { domainInfo["TXT_RECORD"] !== null && domainInfo["TXT_RECORD"].length !== 0 && (
                                    <div>
                                        <ListSubheader style={{backgroundColor: '#111', color: '#fff', textAlign: 'center'}}>TXT Records</ListSubheader>
                                        { domainInfo["TXT_RECORD"].map(item => (
                                            <ListItem {...listStyle} key={item}>{ item }</ListItem>
                                        ))}
                                    </div>
                                )}

                            </List>
                        )}

                        { /* WhoIs Query */ }
                        <div>
                            { whoisData !== "" && (
                                <div style={{marginTop: '10px'}}>
                                    <div style={{backgroundColor: '#111', color: '#fff', padding: '10px', textAlign: 'center'}}>Whois</div>
                                    <div style={{backgroundColor: '#000', color: '#fff', padding: '15px', overflowX: 'scroll'}}>
                                        <pre>{ whoisData }</pre>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </Container>
        </div>
    )
}