import { Container } from "@mui/material"


export const TicketingPage = () => {
    return (
        <div className="homePageClass">
            <Container>
                <h1 className="userHeader">IT Requests</h1>

            </Container>
        </div>
    )
}