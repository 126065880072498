import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListSubheader, TextField, Tooltip } from "@mui/material"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import EditIcon from '@mui/icons-material/Edit';
import { Delete, DeleteForever } from "@mui/icons-material";
import { SnackbarContext } from "../../context/SnackbarContext";
import { WSSBufferedContext } from "../../context/NewWSSContext";
import { CSVLink } from "react-csv";


export const ClientAdminPage = () => {
    const [ localClients, setLocalClients ] = useState([])
    const [ localCSVData, setLocalCSVData ] = useState([])
    const sbC = useContext(SnackbarContext)
    const wssC = useContext(WSSBufferedContext)

    // export client list - csv 
    const csvLink = useRef() 
    const exportClientList = () => {
        console.log("Exporting clients")
        csvLink.current.link.click()
    }

    const updateClients = useCallback(() => {
        wssC.sendWssMessage({action: "getClients"}, (res) => {
            const sortedClients = res.sort((a,b) => {
                if (a.clientid > b.clientid) {
                    return 1
                } else {
                    return -1 
                }
            })

            setLocalClients(sortedClients)
            var csvData = [["Customer", "Service Item"]];
            for (let i =0; i<sortedClients.length;i++) {
                for(let x =0; x < sortedClients[i].categories.length; x++) {
                    // each category 
                    csvData.push([sortedClients[i].clientid.replaceAll(",", ""), sortedClients[i].categories[x].title.replaceAll(",", "")])
                }
            }
            setLocalCSVData(csvData)
        })   
    }, [wssC])

    useEffect(() => {
        updateClients()
    }, [updateClients])

    // Backup Clients - Console Command
    window.getBackupClients = () => {
        console.log(JSON.stringify(localClients))
    }

    // Add Client
    const [ addClientOpen, setAddClientOpen ] = useState(false)
    const [ clientName, setClientName ] = useState("")
    const handleChangeClientName = (e) => setClientName(e.target.value)
    const handleAddClientOpen = () => setAddClientOpen(true)
    const handleAddClientClose = () => setAddClientOpen(false)

    const handleAdminAddClient = () => {
        const clientStruct = {
            "action":"adminAddClient",
            "categories":[], 
            "entryType":"client",
            "id": `${localClients.length +1}`,
            "clientid": clientName,
        }

        wssC.sendWssMessage(clientStruct, () => {
            setClientName("")
            updateClients()
            sbC.showMessage("Added Client", "success")
            handleAddClientClose()
        })
    }

    return (
        <div className="homePageClass">
            <Container>
                <h1 className="userHeader">Client Admin</h1>
                <div>
                    { /* Add Client */}
                    <div style={{marginBottom: '10px', display: 'flex', justifyContent: 'space-between'}}>
                        <Button color="success" variant="contained" onClick={handleAddClientOpen}>Add Client</Button>
                        <Button color="info" variant="contained" onClick={exportClientList}>Export</Button>
                    </div>

                    <div style={{display: 'hidden'}}>
                        <CSVLink
                            enclosingCharacter=""
                            data={localCSVData}
                            filename='clientList.csv'
                            ref={csvLink}
                            target='_blank' />
                    </div>

                    <Dialog open={addClientOpen} onClose={handleAddClientClose}>
                        <DialogTitle>Add Client</DialogTitle>
                        <DialogContent>
                            <div style={{padding: '10px'}}>
                                <TextField label="Client Name" fullWidth onChange={handleChangeClientName} value={clientName} />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" onClick={handleAddClientClose}>Close</Button>
                            <Button variant="contained" color="success" onClick={handleAdminAddClient}>Add</Button>
                        </DialogActions>
                    </Dialog>

                    { /* Client List */}
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <List>
                                { localClients.map((item, i) => (
                                    <div key={`${item.clientid}-${i}`} style={{paddingBottom: '20px'}}>
                                        <ListSubheader style={{backgroundColor: '#000', color: '#fff', border: '1px solid #fff3', display: 'flex', justifyContent: 'space-between'}}>
                                            <div style={{display: 'flex', alignItems: 'center'}}>{ item.clientid }</div>
                                            <EditClient item={item} updateClients={updateClients} />
                                        </ListSubheader>
                                        { item.categories.map(category => (
                                            <ListItem key={category.title} style={{borderBottom: '1px solid #333', display: 'flex', justifyContent: 'space-between'}}>
                                                <div style={{padding: '5px'}}>{ category.title }</div>
                                                { category.rate !== "" && (
                                                    <div style={{padding: '5px'}}>Rate: { category.rate }</div>
                                                )}
                                                
                                            </ListItem>
                                        ))}      
                                    </div>
                                ))}
                            </List>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </div>
    )
}


const EditClient = ({item, updateClients}) => {
    const [ activeClientOpen, setActiveClientOpen ] = useState(false)
    const handleActiveClientOpen = () => {
        setActiveClientOpen(true)
        setLocalCategories(item.categories)
    }
    const handleActiveClientClose = () => {
        setActiveClientOpen(false)
        setLocalCategories(item.categories)
    }

    // delete confirmation
    const wssC = useContext(WSSBufferedContext)
    const sbC = useContext(SnackbarContext)
    const [ deleteOpen, setDeleteOpen ] = useState(false)
    const handleDeleteConfirmOpen = () => setDeleteOpen(true)
    const handleDeleteConfirmClose = () => setDeleteOpen(false)
    const confirmDelete = () => {
        // Confirmed Deletion of client
        wssC.sendWssMessage({"action":"adminDeleteClient", "id":item.id}, () => {
            sbC.showMessage("Deleted Client", "success")
            updateClients()
            handleActiveClientClose()
            handleDeleteConfirmClose()
        })
    }

    // categores
    const [ localCategories, setLocalCategories ] = useState(item.categories)
    const [ newCategory, setNewCategory ] = useState("")
    const [ newRate, setNewRate ] = useState("")
    const handleChangeNewRate = (e) => setNewRate(e.target.value)
    const handleChangeNewCategory = (e) => setNewCategory(e.target.value)
    const addLocalCategory = () => {
        if (newCategory !== "") {
            setLocalCategories([...localCategories, {title: newCategory, rate: newRate}])
            setNewRate("")
            setNewCategory("")
        }
    }
    const removeLocalCategory = (index) => {
        const newCats = localCategories.filter((_, i) => index !== i)
        setLocalCategories(newCats)
    }
    const saveCategories = () => {
        // Save Categories with WSS
        wssC.sendWssMessage({"action":"adminUpdateClient", "id": item.id, "categories": localCategories}, () => {
            updateClients()
            sbC.showMessage("Saved Categories", "success")
            handleActiveClientClose()
        })
    }

    /** Edit Dialog - Categories */
    const [ subHourlyRate, setSubHourlyRate ] = useState("")
    const changeSubHourlyRate = (e) => setSubHourlyRate(e.target.value)
    const [ editSelected, setEditSelected ] = useState()
    const [ editOpen, setEditOpen ] = useState(false)
    const handleEditOpen = (item) => {
        setEditSelected(item)
        setSubHourlyRate(item.rate)
        setEditOpen(true)
    }
    const handleEditClose = () => setEditOpen(false)
    const saveSubHourlyRate = () => {
        // save sub hourly 
        let tempCategories = localCategories;
        for(let i = 0; i<tempCategories.length; i++) {
            if (tempCategories[i].title === editSelected.title) {
                // found 
                tempCategories[i] = {title: editSelected.title, rate: subHourlyRate};
                break;
            }
        }
        handleEditClose()
        setLocalCategories(tempCategories)
    }

    return (
        <div>
            <div style={{padding: '5px'}}>
                <IconButton style={{border: '1px solid #222'}} onClick={handleActiveClientOpen}>
                    <EditIcon  style={{color: '#fff'}}/>
                </IconButton>
            </div>
            
            <Dialog open={activeClientOpen} onClose={handleActiveClientClose}>
                <DialogTitle>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <div>Edit Client</div>
                        <div style={{display: 'flex', marginLeft: '20px'}}>
                            <div style={{padding: '5px'}}>
                                <Tooltip title="Delete Client">
                                    <IconButton style={{border: '1px solid #999'}}  onClick={handleDeleteConfirmOpen}>
                                        <DeleteForever style={{color: '#f00'}} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    
                </DialogTitle>
                <DialogContent>
                    <div style={{padding: '10px', marginBottom: '15px', borderBottom: '1px solid #000'}}>
                        Changes are only active when saved.
                    </div>
                    <div>
                        <div style={{textAlign: 'center', fontWeight: 'bold'}}>{item.clientid}</div>
                        <List>
                            { localCategories.map((item, i) => (
                                <ListItem key={`${item.title}-${i}`} style={{borderBottom: '1px solid #ccc', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}} 
                                    secondaryAction={
                                        <div>
                                            <IconButton onClick={() => removeLocalCategory(i)} style={{border: '1px solid #ccc'}}>
                                                <Delete style={{color: '#f00'}} />
                                            </IconButton>
                                        </div>
                                    }>
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                                            { /** Edit For Hourly Rate */}
                                            <div style={{marginRight: '10px'}}>
                                                <IconButton onClick={() => handleEditOpen(item)} style={{border: '1px solid #ccc'}}>
                                                    <EditIcon />
                                                </IconButton>
                                            </div>
                                            <div style={{marginRight: '20px'}}>{ item.title }</div>
                                        </div>

                                    {item.rate !== "" && (
                                        <div style={{padding: '5px'}}>
                                            <span style={{fontSize: '0.8rem'}}>Hourly Rate: {item.rate}</span>
                                        </div>
                                    )}

                                </ListItem>
                            ))}
                        </List>
                    </div>
                    <div style={{padding: '20px 0 20px 0', display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                        <div style={{padding: '10px'}}>
                            <TextField label="Category" value={newCategory} onChange={handleChangeNewCategory} fullWidth />
                        </div>
                        <div style={{padding: '10px'}}>
                            <TextField label="Hourly Rate" type="number" value={newRate} onChange={handleChangeNewRate} 
                                style={{maxWidth: '150px'}}  />
                        </div>
                        <Button variant="outlined" color="success" onClick={addLocalCategory}>Add</Button>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleActiveClientClose}>Close</Button>
                    <Button variant="contained" color="success" onClick={saveCategories}>Save</Button>
                </DialogActions>
            </Dialog>

            { /* Hourly Rate Dialog */}
            <Dialog
                open={editOpen}
                onClose={handleEditClose}
                >
                <DialogTitle>Edit Category</DialogTitle>
                <DialogContent>
                    { editSelected !== undefined && (
                        <div>
                            <div style={{padding: '10px'}}>
                                { editSelected.title }
                            </div>
                            <div style={{padding: '10px'}}>
                                <TextField value={subHourlyRate} onChange={changeSubHourlyRate} label="Hourly Rate" type="number" fullWidth />
                            </div>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditClose} variant="outlined">Close</Button>
                    <Button onClick={saveSubHourlyRate} variant="contained" color="success">Save</Button>
                </DialogActions>
            </Dialog>

            { /** Confirm Delete Dialog */}
            <Dialog
                open={deleteOpen}
                onClose={handleDeleteConfirmClose}
                >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <div style={{fontWeight: 'bold', textAlign: 'center', borderBottom: '1px solid #000', paddingBottom: '10px'}}><span style={{color: '#f00'}}>Beware:</span>  This is a permanent action</div>
                    <div style={{padding:'10px'}}>
                        Are you sure you want to delete the client & categories?
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleDeleteConfirmClose}>Close</Button>
                    <Button variant="contained" color="error" onClick={confirmDelete}>Delete</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

