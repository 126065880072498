import { Container } from "@mui/material"


export const AnalyticsPage = () => {

    return (
        <div className="homePageClass">
            <Container>
                <h1 className="userHeader">Analytics</h1>
                <div>
                <iframe 
                    title="Analytics Report"
                    height="2125" 
                    src="https://lookerstudio.google.com/embed/reporting/983de2a4-c664-4756-bce8-2f141951bb7a/page/kIV1C"
                    frameBorder="0" 
                    style={{width: '100%',border: 0}} 
                    allowFullScreen ></iframe>
                </div>
            </Container>
        </div>
    )
}