
export const FooterComponent = () => {

    return (
        <div className="footerClass">
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', minHeight: '250px', paddingTop: '20px'}}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <img src={process.env.PUBLIC_URL + '/images/nsg-logo.webp'} style={{height: '60px'}} alt="NSG" />
                </div>
                <div style={{paddingTop:'20px', fontSize: '0.75rem', borderBottom: '1px solid #333', width: '100%', paddingBottom: '20px', textAlign: 'center'}}>
                    &copy; Copyright Next Solutions Group { new Date().getFullYear()}. All Rights Reserved.
                </div>

                <div style={{minHeight: '75px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingTop: '35px', paddingBottom: '50px'}}>
                    
                    <div className="minivanAnim" style={{marginBottom: '20px', position: 'relative'}}>
                        <div className="minivanExhaust"></div>
                        <img src={process.env.PUBLIC_URL + '/images/minivan.webp'} alt="minivan" style={{maxWidth: '150px'}} />
                    </div>
                    <div>
                        Happy <span style={{color: '#52aede'}}>{getDateOfWeek(new Date())}!</span> - minivan
                    </div>
                </div>
            </div>
        </div>
    )
}

// Day of week 
const getDateOfWeek = (date) => {
    var days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
    return days[date.getDay()]
}
