import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, TextField, Tooltip } from "@mui/material"
import { useState } from "react"
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import DownloadIcon from '@mui/icons-material/Download';
import { DeleteForever } from "@mui/icons-material";

export const FileSharingPage = () => {


    return (
        <div className="homePageClass">
            <Container>
                <h1 className="userHeader">
                    File Sharing
                </h1>

                { /** File Sharing  */}
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <CreateFileShare />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FileShareList />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <FilesReceived />
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

const CreateFileShare = () => {
    // Dialog
    const [ open, setOpen ] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    // Name
    const [ name, setName ] = useState("")
    const changeName = (e) => setName(e.target.value)

    // Create
    const createFileShare = () => {
        console.log("Create File Share")
        
        // Done 
        setName("")
        handleClose()
    }



    return (
        <div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '15px'}}>
                <Tooltip title="Create File Share">
                    <Button variant="contained" color="info" onClick={handleOpen}>Create</Button>
                </Tooltip>
            </div>

            <Dialog
                open={open}
                onClose={handleClose}
                >
                <DialogTitle>Create File Share</DialogTitle>
                <DialogContent>
                    <p>Create a file share to receive files.</p>
                    <div style={{padding: '15px'}}>
                        <TextField fullWidth value={name} onChange={changeName} label="Name" />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>Close</Button>
                    <Button variant="contained" color="success" onClick={createFileShare}>Create</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const FileShareList = () => {

    const listHeaderStyle = {backgroundColor: '#000', color: '#fff', borderRadius: '5px', textAlign: 'center'}
    return (
        <div>
            <List>
                <ListSubheader style={{...listHeaderStyle}}>Active File Shares</ListSubheader>
                
                { /* Active File Shares */}
                <ListItem disablePadding>
                    <EditFileShare />
                </ListItem>
            </List>

        </div>
    )
}

const EditFileShare = () => {
    const [ open, setOpen ] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    return (
        <div style={{display: 'flex', flexGrow: '1'}}>
            <div style={{display: 'flex', flexGrow: '1'}}>
                <ListItemButton onClick={handleOpen}>
                    <ListItemIcon>
                        <DriveFileMoveIcon style={{color: '#fff'}} />
                    </ListItemIcon>
                    <ListItemText primary="File Share #1" />
                </ListItemButton>
            </div>

            <Dialog
                open={open}
                onClose={handleClose}
                >
                <DialogTitle>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <div>File Share</div>
                        <div style={{display: 'flex', justifyContent: 'flex-end', paddingLeft: '20px'}}>
                            <Tooltip title="Delete File Share">
                                <IconButton>
                                    <DeleteForever style={{color: '#f00'}}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div>
                        <p>File Share X</p>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const FilesReceived = () => {
    const listHeaderStyle = {backgroundColor: '#000', color: '#fff', borderRadius: '5px', textAlign: 'center'}

    return (
        <div>
            <List>
                <ListSubheader style={{...listHeaderStyle}}>Files Received</ListSubheader>
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <DownloadIcon style={{color: '#fff'}}/>
                        </ListItemIcon>
                        <ListItemText primary="File Received X" />
                    </ListItemButton>
                </ListItem>
            </List>
        </div>
    )
}