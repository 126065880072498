import { Button, Container, Grid, MenuItem, Select, Slider, Switch, TextField } from '@mui/material';
import { useRef, useState } from 'react'
import { QRCode } from 'react-qrcode-logo'
import { SketchPicker } from 'react-color'
import { Fade } from 'react-reveal';

export const QRGeneratorPage = () => {
    const [ qrValue, setQrValue ] = useState("https://nsg-global.com")
    const [ qrBgColor, setQrBgColor ] = useState("#000");
    const [ qrFgColor, setQrFgColor ] = useState("#FFF");
    const [ eyeRound, setEyeRound ] = useState(5)
    const [ errorLevel, setErrorLevel ] = useState("L")
    const [ logoImage, setLogoImage ] = useState("")
    const [ logoWidth, setLogoWidth ] = useState(200);
    const [ logoHeight, setLogoHeight ] = useState(50);
    const [ logoOpacity, setLogoOpacity ] = useState(0.7);
    const [ eyeColor, setEyeColor ] = useState("#4DA8DA")
    const [ qrSize, setQRSize ] = useState(320)
    const [ removeQR, setRemoveQr ] = useState(false)
    const [ qrPadding, setQrPadding ] = useState(10)
    const [ logoStyle, setLogoStyle ] = useState("square")
    const [ logoPadding, setLogoPadding ] = useState(0)

    const changeRemoveQr = () => {
        setRemoveQr(!removeQR)
    }

    const [ qrStyleSet, setQrStyle ] = useState("dots")
    const changeQrStyle = () => {
        switch (qrStyleSet) {
            case "dots":
                setQrStyle("squares")
                break
            case "squares":
                setQrStyle("dots")
                break
            default:
                console.log("incorrect style")
        }
    }

    const uploadInputRef = useRef();
    const handleLogoChange = (evt) => {
        try {
            URL.revokeObjectURL(evt.target.files[0])
            setLogoImage(URL.createObjectURL(evt.target.files[0]))
        } catch(e) {
            console.log(e)
        }
    }

    const resetSettings = () => {
        setQrValue("https://nsg-global.com")
        setQrBgColor("#000")
        setQrFgColor("#FFF")
        setEyeRound(5)
        setErrorLevel("L")
        setLogoImage("")
        setEyeColor("#4DA8DA")
        setRemoveQr(false)
        setQRSize(320)
        setQrPadding(10)
        setLogoPadding(0)
        setLogoStyle("square")
        uploadInputRef.current.value = "";
    }

    // Save | Download image
    function downloadImage(data, filename = 'qr.png') {
        var a = document.createElement('a');
        a.href = data;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
    }

    const saveImage = () => {
        var canvas = document.getElementById("react-qrcode-logo"); 
        var image = canvas.toDataURL("image/png", 1);  // here is the most important part because if you dont replace you will get a DOM 18 exception.
        downloadImage(image, "qrcode.png"); // it will save locally
    }

    return (
        <div className="homePageClass">
            <Fade>
                <Container>
                    <div>
                        <h1 className="userHeader">QR Generator</h1>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button color='warning' style={{width: '100px'}} onClick={() => resetSettings()} variant='outlined'>Reset</Button>
                    </div>

                    <div style={{paddingBottom: '40px', display: 'flex', flexDirection: 'column'}}>
                        <div>
                            <div>
                                <Grid container>
                                    <Grid item xs={12} md={4}>
                                        <div style={{padding: '10px'}}>URL</div>
                                        <TextField fullWidth value={qrValue} onChange={(e) => {setQrValue(e.target.value)}} label="URL" variant="filled" className='qrText' />
                                    </Grid>
                                    <Grid item xs={12} md={4} style={{textAlign: 'center'}}>
                                        <div>
                                            <div style={{padding: '10px'}}>QR Style</div>
                                            <Button variant='contained' onClick={() => changeQrStyle()} className='qrButton'>Change { qrStyleSet }</Button>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4} style={{textAlign: 'center'}}>
                                        <div style={{padding: '10px'}}>Error Correction Level</div>
                                        <Select onChange={(e) => setErrorLevel(e.target.value)} defaultValue={"L"} 
                                            style={{width: '250px', color: '#000', backgroundColor: '#FFF', marginBottom: '20px'}}>
                                            <MenuItem value="L">L</MenuItem>
                                            <MenuItem value="M">M</MenuItem>
                                            <MenuItem value="Q">Q</MenuItem>
                                            <MenuItem value="H">H</MenuItem>
                                        </Select>
                                    </Grid>
                                </Grid>


                                <Grid container spacing={5} padding={2}>
                                    <Grid item xs={12} md={4}>
                                        <div style={{padding: '10px'}}>QR Size</div>
                                        <Slider defaultValue={256} min={256} step={32} max={512} value={qrSize} onChange={(e) => setQRSize(e.target.value)} />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <div style={{padding: '10px'}}>QR Padding</div>
                                        <Slider defaultValue={10} min={10} step={10} max={100} value={qrPadding} onChange={(e) => setQrPadding(e.target.value)} />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <div style={{padding: '10px'}}>Eye Rounding</div>
                                        <Slider defaultValue={5} min={0} max={50} value={eyeRound} onChange={(e) => setEyeRound(e.target.value)} />
                                    </Grid>
                                </Grid>

                                <div>
                
                                </div>
                                <div style={{borderTop: '1px solid #ccc'}}>
                                    <div style={{padding: '10px'}}>Logo Settings</div>
                                    <Grid container style={{marginBottom: '30px'}} spacing={2}>
                                        <Grid item xs={12} md={4} style={{textAlign: 'center', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                            <Button
                                                variant="contained"
                                                component="label"
                                                className='qrButton'
                                                >
                                                Upload Logo Image
                                                <input
                                                    ref={uploadInputRef}
                                                    type="file"
                                                    hidden
                                                    onChange={handleLogoChange}
                                                    multiple={false}
                                                />
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} md={4} style={{textAlign: 'center', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                            <div style={{padding: '10px'}}>Remove QR Behind Logo (Test QR After)</div>
                                            <Switch value={removeQR} onChange={() => changeRemoveQr()} />
                                        </Grid>
                                        <Grid item xs={12} md={4} style={{textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                                            <div style={{padding: '10px'}}>Logo Padding Style</div>
                                            <Select onChange={(e) => setLogoStyle(e.target.value)} value={logoStyle}
                                                style={{width: '250px', color: '#000', backgroundColor: '#FFF', marginBottom: '20px'}}>
                                                <MenuItem value="square">Square</MenuItem>
                                                <MenuItem value="circle">Circle</MenuItem>
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12} md={3} style={{textAlign: 'center', marginTop: '20px', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                            <TextField value={logoWidth} onChange={(e) => {setLogoWidth(e.target.value)}} 
                                                label="Logo Image Width" variant="filled" className='qrText' type='number' />
                                        </Grid>
                                        <Grid item xs={12} md={3} style={{textAlign: 'center', marginTop: '20px', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                            <TextField value={logoHeight} onChange={(e) => {setLogoHeight(e.target.value)}} 
                                                label="Logo Image Height" variant="filled" className='qrText' type='number'  />
                                        </Grid>
                                        <Grid item xs={12} md={3} style={{textAlign: 'center', marginTop: '20px', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                                            <div style={{padding: '10px'}}>Logo Padding</div>
                                            <Slider defaultValue={10} min={0} max={100} step={1} value={logoPadding} onChange={(e) => setLogoPadding(e.target.value)} />
                                        </Grid>
                
                                        <Grid item xs={12} md={3} style={{textAlign: 'center', marginTop: '20px', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                                            <div style={{padding: '10px'}}>Logo Opacity</div>
                                            <Slider defaultValue={0.7} min={0} max={1} step={0.05} value={logoOpacity} onChange={(e) => setLogoOpacity(e.target.value)} />
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>

                            <div style={{borderTop: '1px solid #ccc', padding: '10px'}}>
                                <div>Color Settings</div>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', flexWrap: 'wrap'}}>
                                <div>
                                    <h3>Foreground Color</h3>
                                    <SketchPicker color={qrFgColor} onChangeComplete={(color) => setQrFgColor(color.hex) } />
                                </div>
                                <div>
                                    <h3>Background Color</h3>
                                    <SketchPicker color={qrBgColor} onChangeComplete={(color) => setQrBgColor(color.hex) } />
                                </div>
                                <div>
                                    <h3>Eye Color</h3>
                                    <SketchPicker color={eyeColor} onChangeComplete={(color) => setEyeColor(color.hex) } />
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr style={{marginTop: '50px', marginBottom: '50px'}} />

                    <h3>QR Code</h3>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <QRCode value={qrValue}
                            size={qrSize}
                            qrStyle={qrStyleSet}
                            eyeRadius={eyeRound}
                            eyeColor={eyeColor}
                            bgColor={qrBgColor} 
                            fgColor={qrFgColor} 
                            ecLevel={errorLevel}
                            removeQrCodeBehindLogo={removeQR}
                            logoImage={logoImage}
                            logoWidth={logoWidth}
                            logoHeight={logoHeight}
                            logoOpacity={logoOpacity}
                            logoPadding={logoPadding}
                            logoPaddingStyle={logoStyle}
                            quietZone={qrPadding}
                        />
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '20px'}}>
                        <Button className='qrButton' variant='contained' onClick={() => saveImage()} style={{maxWidth: '150px'}}>Save QR</Button>
                    </div>
                </Container>
            </Fade>
        </div>
    )
}