import { Container, Grid, Tooltip } from "@mui/material"
import { Link } from "react-router-dom"
import { AppConstants } from "../constants/AppConstants"
import { RenderAdmin, RenderUser } from "../context/NewLoginContext"
import { Fade } from "react-reveal"
import { ItemIcon } from "../constants/ItemIcon"


export const DashboardPage = () => {

    return (
        <div className="homePageClass">
            <Fade>
                <Container style={{paddingBottom: '150px'}}>
                    <h1 className="userHeader">Dashboard</h1>

                    <RenderUser>
                        <Grid container>
                            { AppConstants.UserLinks.map(item => (
                                <Grid item xs={12} md={3} style={{padding: '10px'}} key={item.title}>
                                    <Tooltip title={item.tooltip}>
                                        { !item.external ? (
                                            <Link to={item.href} className="destyleLink">
                                                <div className="gridClass">
                                                    { item.icon }&nbsp;&nbsp;{ item.title }
                                                </div>
                                            </Link>
                                        ) : (
                                            <a href={item.href} className="destyleLink" target="_blank" rel="noreferrer">
                                                <div className="gridClass externalLink">
                                                    { item.icon }&nbsp;&nbsp;{ item.title }
                                                </div>
                                            </a>
                                        )}
                                    </Tooltip>
                                </Grid>
                            ))}
                        </Grid>

                        <h1 className="userHeader" style={{paddingTop: "15px"}}>Tools</h1>
                        <Grid container>
                            { /* User Tools */}
                            { AppConstants.UserToolLinks.map(item => (
                                <Grid item xs={12} md={3} style={{padding: '10px'}} key={item.title}>
                                    <Tooltip title={item.tooltip}>
                                    <Link to={item.href} className="destyleLink">
                                        <div className="gridClass">
                                            { item.icon }&nbsp;&nbsp;
                                            { item.title }
                                        </div>
                                    </Link>
                                    </Tooltip>
                                </Grid>
                            ))}

                            { /* Admin Tools */}
                            <RenderAdmin>
                                { AppConstants.AdminToolLinks.map(item => (
                                    <Grid item xs={12} md={3} style={{padding: '10px'}} key={item.title}>
                                        <Tooltip title={item.tooltip}>
                                        <Link to={item.href} className="destyleLink">
                                            <div className="gridClassAdmin">
                                                { item.icon }&nbsp;&nbsp;
                                                { item.title }
                                            </div>
                                        </Link>
                                        </Tooltip>
                                    </Grid>
                                ))}
                            </RenderAdmin>

                        </Grid>
                    </RenderUser>

                    <RenderAdmin>
                        <h1 className="userHeader" style={{paddingTop: "15px"}}>Admin</h1>
                        <Grid container>
                            { AppConstants.AdminPageLinks.map(item => (
                                <Grid item xs={12} md={3} style={{padding: '10px'}} key={item.title}>
                                    <Link to={item.href} className="destyleLink">
                                        <div className="gridClassAdmin" style={{backgroundColor: item.todo ? "#5a5a3e" : "#000"}}>
                                            { item.icon }&nbsp;&nbsp;
                                            { item.title } 
                                        </div>
                                    </Link>
                                </Grid>
                            ))}
                        </Grid>
                    </RenderAdmin>
                </Container>
            </Fade>
        </div>
    )
}

