import { createContext, useCallback, useEffect, useReducer, useRef, useState } from "react";
import { msalConfig } from "../auth/authConfig";
import { useIsAuthenticated } from "@azure/msal-react";
import { AccountDetailBar, LoginPage } from "./NewLoginContext";
import Puff from "react-loading-icons/dist/esm/components/puff";
import { Fade } from "react-reveal";

// Socket Queue for Callbacks
var socketQueueId = 0;
var socketQueue = {};

// WSS Context
const WSSURL = "wss://wss.nsg-global.com/";
export const WSSBufferedContext = createContext()

// WSS Reducer
function reducer(state, action) {
    switch (action.type) {
        case "resetToken": 
            return {
                ...state,
                wssAccountLoaded: false,
                accountLoaded: false,
                accountToken: ""
            }
        case "accountLoaded":
            return {
                ...state,
                accountLoaded: true,
                accountDetails: action.accountDetails,
                accountToken: action.accountToken,
            }
        case "wssAccountLoaded":
            let isUser = false;
            let isAdmin = false; 
            if (action.accountDetails.roles.length !== 0) {
                if (action.accountDetails.roles.includes("Admin")) {
                    isAdmin = true;
                    isUser = true;
                } else if (action.accountDetails.roles.includes("User")) {
                    isUser = true;
                }
            }
            return {
                ...state,
                wssAccountLoaded: true,
                wssAccountDetails: action.accountDetails,
                isAdmin: isAdmin,
                isUser: isUser,
            }
        default: 
            return {
                ...state, 
            }
    }
} 

// Initial Args
const initArgs = {
    // MSAL 
    accountLoaded: false,
    accountDetails: {},
    accountToken: "",

    // WSS
    wssAccountLoaded: false,
    wssAccountDetails: {},

    // User Groups
    isAdmin: false,
    isUser: false,
}


// WSS Buffered Provider
export const WSSBufferedProvider = ({children, pca}) => { 
    const [ reducerState, dispatch] = useReducer(reducer, initArgs)
    const [ wssReady, setWssReady ] = useState(false)
    const isAuthenticated = useIsAuthenticated()
    const ws = useRef(null)

    // Send message
    const sendWssMessage = useCallback((message, cb) => {
        socketQueueId++;
        if (typeof(cb) === 'function') {
            socketQueue['i_'+socketQueueId] = cb;
        }
        ws.current.send(JSON.stringify({cmdId: `${socketQueueId}`, ...message}))
    }, []) 

    // On Open 
    const onOpenWss = useCallback(() => {  
        console.log("[ws opened]")
        setWssReady(true)
    }, [])

    // On Token Receive 
    useEffect(() => {
        if (reducerState.accountToken !== "" && wssReady) {
            sendWssMessage({"token": reducerState.accountToken})
        }
    }, [reducerState.accountToken, wssReady, sendWssMessage])

    // On Close
    const onCloseWss = useCallback(() => {
        console.log("[ws closed]")
    }, [])

    // Window Tab Focus - Refresh
    const windowRefocus = (e) => {
        if (e.target.visibilityState === "visible" && ws.current.readyState !== 1) {
            console.log('reload')
            window.location.reload()
        }
    };
    window.addEventListener("visibilitychange", windowRefocus)
 
    // Connect WSS
    const pcaRef = useRef(pca)
    useEffect(() => {
        if (isAuthenticated) {
            // Reducer
            pcaRef.current.acquireTokenSilent(msalConfig).then(res => {  
                // need to get new token 
                const acctDetails = pcaRef.current.getActiveAccount()
                const acctToken = res.idToken;
                dispatch({type: "accountLoaded", accountDetails: acctDetails, accountToken: acctToken})
            })

            // Websocket Stuff
            ws.current = new WebSocket(WSSURL);
            ws.current.onopen = () => onOpenWss(); 
            ws.current.onclose = () => onCloseWss(); 
            ws.current.onmessage = (event) => {
                const jsonData = JSON.parse(event.data) 
                switch(jsonData.action) {
                    case "login":
                        //console.log(jsonData.result)
                        dispatch({type: "wssAccountLoaded", accountDetails: jsonData.result})  
                        break
                    default:
                        // check for callbacks & execute 
                        if ((typeof socketQueue['i_'+jsonData.cmdId]) === "function") { 
                            const cbFunc = socketQueue['i_'+jsonData.cmdId]; 
                            cbFunc(jsonData.result)
                            delete socketQueue['i_'+jsonData.cmdId];
                            return;
                        }
                }
            }
            const wsCurrent = ws.current; 
            return () => {
                if (wsCurrent.readyState === 1) {
                    wsCurrent.close() 
                }
            }
        }
    }, [isAuthenticated, onOpenWss, onCloseWss]) 

    // State Exports
    const state = {
        // State Export 
        reducerState, 
        isAuthenticated,

        // Functions 
        sendWssMessage,
    }


    return (
        <WSSBufferedContext.Provider value={state}> 
            { !isAuthenticated  ? (  
                <LoginPage />
            ) : (
                <div>
                    { reducerState.wssAccountLoaded && wssReady ? (
                        <Fade>
                            <div>
                                <AccountDetailBar />
                                <div>{children}</div>
                            </div>
                        </Fade>
                    ) : (
                        <div style={{minHeight: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Puff /></div> 
                    )}
                </div>
            )}
        </WSSBufferedContext.Provider>
    )
}