import { Button, Container, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { Puff } from "react-loading-icons"
import { WSSBufferedContext } from "../../context/NewWSSContext"
import { AutoAwesome, CloudUpload } from "@mui/icons-material"
import { SnackbarContext } from "../../context/SnackbarContext"
import { styled } from '@mui/material/styles';

const currentModels = [
    {"title":"Minivan", "model":"personal", "note":"Personal AI Assistant"},
    {"title":"Image", "model":"llava", "note":"Image Recognition"},
]

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export const MinivanAIPage = () => {
    const [ loading, setLoading ] = useState(false)
    const [ prompt, setPrompt ] = useState("")
    const [ aiResponse, setAiResponse ] = useState([])
    const handleChangePrompt = (e) => setPrompt(e.target.value)
    const wssC = useContext(WSSBufferedContext)
    const sbC = useContext(SnackbarContext)

    // model selection
    const [ modelSelected, setModelSelected ] = useState("personal")
    const handleChangeModelSelected = (e) => setModelSelected(e.target.value)

    // image selection
    const [ imageUploaded, setImageUploaded ] = useState("")

    const handleSubmit = () => {
        var currentPrompt = "";
        if (prompt === "" && modelSelected === "personal") {
            sbC.showMessage("Prompt is mandatory", "warning")
            return 
        } else if (modelSelected === "llava") {
            currentPrompt = "Describe the image";
        } else {
            currentPrompt = prompt;
        }

        setLoading(true)
        const allResponses = aiResponse;
        const curRequest = [...allResponses, {type: "client", text: currentPrompt}]
        setAiResponse(curRequest)

        let queryParams = {"action":"aiGenerate", "prompt":currentPrompt, "model":modelSelected};
        if (modelSelected === "llava" && imageUploaded !== "") {
            queryParams["images"] = [imageUploaded];
        }
        wssC.sendWssMessage(queryParams, (res) => {
            setLoading(false)
            const allResponses = [...aiResponse, {type: "client", text: currentPrompt}, {type: "server", text: res.response}]
            setAiResponse(allResponses)
            setPrompt("")
        })
    }

    useEffect(() => {
        // Scroll to bottom 
        if (modelSelected === "personal") {
            var objDiv = document.getElementById("aiResponses");
            objDiv.scrollTop = objDiv.scrollHeight;
        }
    }, [aiResponse, modelSelected])

    // reset responses with different model selection 
    useEffect(() => {
        setAiResponse([])
    }, [modelSelected])


    // enter key without shift
    const onKeyPress = (e) => {
        if (!e.shiftKey && e.keyCode === 13) {
            handleSubmit()
        }
    }

    const clearResponses = () => {
        setAiResponse([])
    }

    // Ai File Upload
    const handleFileUpload = (event) => {
        var reader = new FileReader();
        reader.onloadend = function () {
            const imageData = reader.result.split(",")[1];
            sbC.showMessage("Image Uploaded", "success")
            setImageUploaded(imageData)
        }
        reader.readAsDataURL(event.target.files[0]);
    }

    return (
        <div className="homePageClass">
            <Container>
                <h1 className="userHeader" style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{maxHeight: '25px'}}>
                        { loading && (
                            <Puff />
                        )}
                    </div>
                    Minivan AI
                </h1>

                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
                    <div>
                        <FormControl>
                        <InputLabel id="modelSelectedLabel" sx={{color: '#fff', backgroundColor: '#000', padding: '3px', borderRadius: '3px'}}>AI Model</InputLabel>
                        <Select
                            sx={{
                                "& .MuiSelect-select": {
                                    backgroundColor: '#000',
                                    color: '#fff'
                                },
                                minWidth: '120px',
                                "& .MuiSvgIcon-root": {
                                    color: '#fff'
                                }
                            }}
                            labelId="modelSelectedLabel"
                            id="modelSelected"
                            onChange={handleChangeModelSelected}
                            value={modelSelected}
                            label="Model">
                            { currentModels.map(model => (
                                <MenuItem value={model.model}>{model.title}</MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    </div>
                    { modelSelected === "personal" && (
                        <div>
                            <Button onClick={clearResponses} variant="contained">Clear</Button>
                        </div>
                    )}
                </div>

                { /** Upload Image */}
                { modelSelected === "llava" && (
                    <div style={{padding: '15px'}}>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            Upload an Image to run through AI to describe the uploaded image.
                        </div>
                        <div style={{padding: '10px'}}>
                            <Button 
                                color="info"
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                sx={{margin: '10px'}}
                                startIcon={<CloudUpload />}>Upload
                                <VisuallyHiddenInput
                                    accept=".png,.jpeg,.jpg,.webp,.gif,.bmp"
                                    type="file"
                                    onChange={handleFileUpload}
                                />
                            </Button>
                            <Button onClick={handleSubmit} sx={{margin: '10px'}} variant="contained">Submit</Button>
                        </div>
                        <div id="aiResponses" 
                            style={{
                                paddingBottom: '20px', overflowY: 'scroll', padding: '10px', backgroundColor: '#000', border: '1px solid #444', color: '#fff', 
                                height: '250px', borderRadius: '20px', marginBottom:"10px",
                            }}>
                            { aiResponse.map((item, i) => (
                                <div key={i}>
                                    { item.type === "client" ? (
                                        <div style={{marginRight: '20px'}}>
                                            <div style={{ flexDirection: 'column', display: 'flex', backgroundColor: '#eee', color: '#111', padding: '10px', borderRadius: '10px', margin: '15px'}}>
                                                <div style={{fontSize: '0.8rem', borderBottom: '1px solid #0003', marginBottom: '5px'}}>{ wssC.reducerState.accountDetails.username}</div>
                                                <div style={{lineHeight: '1.5rem'}}>{ item.text }</div>
                                            </div>
                                        </div> 
                                    ) : (
                                        <div style={{marginLeft: '20px'}}>
                                            <div style={{ flexDirection: 'column', display:'flex', backgroundColor: '#a9e6ff', color: '#000', padding: '10px', borderRadius: '10px', margin: '15px'}}>
                                                <div style={{fontSize: '0.8rem', borderBottom: '1px solid #0003', marginBottom: '5px', fontWeight: 'bold', alignItems: 'center', display: 'flex'}}>
                                                    <AutoAwesome style={{margin: '5px'}}/>
                                                    Minivan AI
                                                </div>
                                                <div style={{lineHeight: '1.5rem'}}>{ item.text }</div>
                                            </div>
                                        </div> 
                                    )}
                                </div>
                            )) }
                        </div>
                    </div>
                )}
                { modelSelected === "personal" && (
                    <div>
                        <div id="aiResponses" 
                            style={{
                                paddingBottom: '20px', overflowY: 'scroll', padding: '10px', backgroundColor: '#000', border: '1px solid #444', color: '#fff', 
                                height: '300px', borderRadius: '20px', marginBottom:"10px",
                            }}>
                            { aiResponse.map((item, i) => (
                                <div key={i}>
                                    { item.type === "client" ? (
                                        <div style={{marginRight: '20px'}}>
                                            <div style={{ flexDirection: 'column', display: 'flex', backgroundColor: '#eee', color: '#111', padding: '10px', borderRadius: '10px', margin: '15px'}}>
                                                <div style={{fontSize: '0.8rem', borderBottom: '1px solid #0003', marginBottom: '5px'}}>{ wssC.reducerState.accountDetails.username}</div>
                                                <div style={{lineHeight: '1.5rem'}}>{ item.text }</div>
                                            </div>
                                        </div> 
                                    ) : (
                                        <div style={{marginLeft: '20px'}}>
                                            <div style={{ flexDirection: 'column', display:'flex', backgroundColor: '#a9e6ff', color: '#000', padding: '10px', borderRadius: '10px', margin: '15px'}}>
                                                <div style={{fontSize: '0.8rem', borderBottom: '1px solid #0003', marginBottom: '5px', fontWeight: 'bold', alignItems: 'center', display: 'flex'}}>
                                                    <AutoAwesome style={{margin: '5px'}}/>
                                                    Minivan AI
                                                </div>
                                                <div style={{lineHeight: '1.5rem'}}>{ item.text }</div>
                                            </div>
                                        </div> 
                                    )}
                                </div>
                            ))}
                        </div>
                        <div>
                            <div style={{display: 'flex'}}>
                                <TextField 
                                    multiline
                                    onKeyDown={onKeyPress}
                                    sx={{
                                        backgroundColor: '#000',
                                        color: '#fff',
                                        "& .MuiFormLabel-root": {
                                            color: '#ccc'
                                        }
                                    }}
                                    inputProps={{
                                        style: {
                                        color: '#fff'
                                        }
                                    }}
                                    variant="filled"
                                    value={prompt} onChange={handleChangePrompt} label="Prompt" fullWidth />
                                <Button variant="contained" color="info" onClick={handleSubmit}>Submit</Button>
                            </div>
                        </div>
                    </div>
                )}
                


            </Container>
        </div>
    )
}