import { Box, Tab, Container, FormControl, Select, MenuItem, InputLabel, Alert, Grid, List, ListItem, ListSubheader, ListItemIcon, Accordion, AccordionSummary, AccordionDetails, TextField, Button } from "@mui/material"
import {  useContext, useEffect, useRef, useState } from "react"
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ArrowDropDownIcon, DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Fade } from "react-reveal";
import { PieChart } from "@mui/x-charts";
import { InfoOutlined } from "@mui/icons-material";
import { WSSBufferedContext } from "../../context/NewWSSContext";

function getFormattedDate(date) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
  
    return month + '/' + day + '/' + year;
}

export const ReportsPage = () => {
    const [ value, setValue ] = useState("1")
    const handleChange = (e, newValue) => {
        setValue(newValue)
    }
    
    // Date
    const currentDate = new Date();
    const toDateEnd = dayjs(currentDate).endOf('day')
    const fromDateStart = dayjs(currentDate).startOf('month').startOf('day')

    // Date Selection
    const [ fromDate, setFromDate ] = useState(fromDateStart)
    const [ toDate, setToDate ] = useState(toDateEnd)


    const dateStyle = {
        "& .MuiInputBase-root": {
            backgroundColor: '#000',
            color: '#fff'
        },
        "& .MuiIconButton-root": {
            color: '#fff'
        },
        "& .MuiInputLabel-root": {
            color: '#ccc!important',
            backgroundColor: '#000',
            padding: '3px',
            borderRadius: '5px'
        },
        marginBottom: '15px'
    }

    // Quickbooks export for month 
    const wssC = useContext(WSSBufferedContext)
    const exportQuickbooks = () => {
        // User List
        console.log("Exporting Quickbooks")
        // Get Export from Backend
        wssC.sendWssMessage({"action":"adminQuickbooksReport", "fromDate": fromDate, "toDate": toDate}, (res) => {
            // Report Generated
            const csvHeaders = [["Name", "Transaction Date", "Customer", "Service Item", "Payroll item", "Duration", "Class", "Billable", "Notes"]]
            for (let x = 0; x < Object.keys(res).length; x++) {
                // Each user 
                const curUserKey = Object.keys(res)[x]
                const curUserLine = res[curUserKey];

                // Date Formatting
                for (let i = 0; i < curUserLine.length; i++) {
                    const curData = curUserLine[i];
                    const curDate = new Date(curUserLine[i].date.replace("Z", ""))
                    const dateFormatted = getFormattedDate(curDate)
                    const finalHours = curData.hours;
                    const userLine = [
                        curUserKey, 
                        dateFormatted, 
                        curData.client.replaceAll(",", ""), 
                        curData.category.replaceAll(",", ""), 
                        "Regular Pay", 
                        finalHours,
                        "",
                        "",
                        curData.note.replaceAll(",", "").replaceAll("\n", "")
                    ] 
                    csvHeaders.push(userLine)
                }
            }

            let csvHeadersNewLine = csvHeaders.join("\r\n")

            // export 
            const csvData = new Blob([csvHeadersNewLine], { type: 'text/csv' });
            const csvURL = URL.createObjectURL(csvData);
            const link = document.createElement('a');
            link.href = csvURL;
            link.download = `quickbooks.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
    }

    return (
        <div className="homePageClass">
            <Fade>
                <Container>
                    <h1 className="userHeader">Reports</h1>

                    { /* Quickbooks Export */}
                    <div style={{display: 'flex', justifyContent: 'flex-end', paddingBottom: '15px'}}>
                        <Button variant="contained" onClick={exportQuickbooks}>Export Quickbooks</Button>
                    </div>

                    { /* Date Selection */}
                    <div style={{flexWrap: 'wrap', padding: '10px 0 15px 0', display: 'flex', justifyContent: 'space-evenly', borderBottom: '1px solid #444', marginBottom: '15px'}}>
                        <DatePicker label="From Date" sx={{...dateStyle}} value={fromDate} 
                            onChange={(value) => setFromDate(value.startOf("day"))} maxDate={dayjs(currentDate)} />
                        <DatePicker label="To Date" sx={{...dateStyle}} value={toDate} 
                            onChange={(value) => setToDate(value.startOf("day"))} maxDate={dayjs(currentDate)} />
                    </div>

                    { /* Tab Selection */}
                    <TabContext value={value}>
                        <Box sx={{width: "100%"}}>
                            <TabList onChange={handleChange} sx={{
                                '& .MuiTab-root': {
                                    color: '#ccc',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    backgroundColor: 'transparent',
                                },
                                '& .Mui-selected': {
                                    borderRadius: '5px 5px 0 0',
                                    color: '#fff!important',
                                    backgroundColor: '#000',
                                },
                            }}>
                                <Tab label="User Reports" value="1" />
                                <Tab label="Client Reports" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1"><UserReports fromDate={fromDate} toDate={toDate} /></TabPanel>
                        <TabPanel value="2"><ClientReports  fromDate={fromDate} toDate={toDate} /></TabPanel>
                    </TabContext>
                </Container>
            </Fade>
        </div>
    )
}



const UserReports = ({fromDate, toDate}) => {
    const [ userList, setUserList ] = useState([]) 
    const [ selectedUser, setSelectedUser ] = useState("") 

    // Filter Data
    const [ filter, setFilter ] = useState("")
    const [ localReport, setLocalReport ] = useState([])
    const [ filteredReport, setFilteredReport ] = useState([])
    const handleChangeFilter = (e) => {
        const filterValue = e.target.value;
        // filter results 
        let filteredReport = [];
        for (let i = 0; i < localReport.length;i++) {
            if (localReport[i].category.toLowerCase().includes(filterValue.toLowerCase())) {
                filteredReport.push(localReport[i])
            }
        }
        setFilteredReport(filteredReport)
        setFilter(filterValue)
    }


    const onChangeUser = (e) => {
        setSelectedUser(e.target.value)
    }

    const wssC = useRef(useContext(WSSBufferedContext))
    useEffect(() => {
        wssC.current.sendWssMessage({action: "adminUsers"}, (res) => {
            setUserList(res)
            if (res.length > 0) {
                setSelectedUser(res[0].userPrincipalName)
            }
        })
    }, [])

    // total Hours
    const [ totalHours, setTotalHours ] = useState(0)
    const [ pieData, setPieData ] = useState([])

    // local clicked
    const [ clickedData, setClickedData ] = useState({})
    const onItemClick = (e, data) => {
        setClickedData(pieData[data.dataIndex])
    }

    // on change of from, to, or user
    useEffect(() => {
        // do wss update 
        if (selectedUser !== undefined) {
            const dateUpdate = {
                "action":"adminReport", 
                "user": selectedUser, 
                "fromDate": fromDate.toISOString(), 
                "toDate":toDate.toISOString()
            }
            wssC.current.sendWssMessage(dateUpdate, (res) => {
                // get user report 
                const includesClient = (hourMap, clientName) => {
                    const included = hourMap.filter(item => item.label === clientName)
                    if (included.length > 0) {
                        return true;
                    } else {
                        return false;
                    }
                }

                // Total Hours 
                let totalClientHourMap = [];
                let totalHours = 0;
                var tempHourMap = res;
                for(let i =0; i < tempHourMap.length; i++) {
                    const curHour = parseFloat(tempHourMap[i].hours);
                    totalHours += curHour;
        
                    const curClient = tempHourMap[i].client;
                    if (!includesClient(totalClientHourMap, curClient)) {
                        totalClientHourMap.push({label: curClient, value: curHour})
                    } else {
                        // Add Hours 
                        for(let x = 0; x < totalClientHourMap.length; x++) {
                            if (totalClientHourMap[x].label === curClient) {
                                totalClientHourMap[x].value += parseFloat(curHour)
                            }
                        }
                    }
                }
                
                // add id 
                const dataMap = totalClientHourMap.map((item, i) => ({...item, id: i}))
                setPieData(dataMap)
                setTotalHours(totalHours)
        
                if (dataMap.length > 0) {
                    setClickedData(dataMap[0])
                }
                
                setLocalReport(res)
                setFilteredReport(res)
            })
        }
    }, [fromDate, toDate, selectedUser])


    return (
        <div className="reportTooltip">
            <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                <h2>User Reports</h2>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <FormControl>
                        <InputLabel id="user-label1" sx={{color: '#fff', backgroundColor: '#000', padding: '3px', borderRadius: '3px'}}>User</InputLabel>
                        <Select
                            labelId="user-label"
                            sx={{
                                "& .MuiSelect-select": {
                                    backgroundColor: '#000',
                                    color: '#fff'
                                },
                                minWidth: '120px',
                                "& .MuiSvgIcon-root": {
                                    color: '#fff'
                                }
                            }}
                            label="User"
                            value={selectedUser}
                            onChange={onChangeUser}
                            >
                            { userList.map(item => (
                                <MenuItem key={item.userPrincipalName} value={item.userPrincipalName}>{ item.userPrincipalName}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>

            { /** Alert Box Entries */}
            { selectedUser !== "" && (
                <div style={{paddingTop: '15px'}}>
                    { localReport.length === 0 ? (
                        <Alert variant="filled" severity="error">
                            No User Entries Found.
                        </Alert>
                    ) : (
                        <Alert variant="filled" severity="success">
                            { `${localReport.length} Entries Found - Total Hours ${totalHours}` }
                        </Alert>
                    )}
                </div>
            )}

            { /** Local Report */}
            { localReport.length !== 0 && (
                <Grid container style={{paddingTop: '20px'}}>
                    <Grid item xs={12} md={6} style={{minHeight: '125px', display: 'flex', justifyContent: 'center'}}>
                        <PieChart
                            slotProps={{
                                legend: {
                                    hidden: true,
                                    labelStyle: {
                                        fill: '#fff'
                                    }
                                }
                            }}
                            onItemClick={onItemClick}
                            sx={{
                                "& .MuiChartsLegend-root":{color: '#fff!important', fill: "#fff"}
                            }}
                            series={[
                                {
                                    data: pieData,

                                    highlightScope: { fade: 'global', highlight: 'item' },
                                    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                    innerRadius: 30,
                                    outerRadius: 100,
                                    paddingAngle: 5,
                                    cornerRadius: 3,
                                },
                            ]}
                            width={400}
                            height={200}
                            />
                    </Grid>
                    <Grid item xs={12} md={6} style={{display: 'flex', alignItems: 'center', padding: '10px'}}>
                        <List style={{display: 'flex', flexDirection: 'column'}}>
                            <ListItem>
                                <div style={{display: 'flex'}}>
                                    <div style={{fontWeight: 'bold'}}>Client:&nbsp;</div>
                                    <div>{clickedData.label}</div>
                                </div>
                            </ListItem>
                            <ListItem><b>Total Hours:</b>&nbsp;{clickedData.value}</ListItem>
                        </List>
                    </Grid>

                    { /** All Entry Map  */}
                    <Grid item xs={12} md={12} style={{paddingTop: '20px'}}>
                        { /* Filter */}
                        <div style={{backgroundColor: '#000', marginBottom: '10px'}}>
                            <TextField variant="filled" fullWidth label="Client Filter"
                                value={filter}
                                onChange={handleChangeFilter}
                                InputLabelProps={{
                                    style: {
                                        color: '#ccc',
                                    }
                                }}
                                inputProps={{
                                    style: {
                                        color: '#fff'
                                    }
                                }}/>
                        </div>
                        <List style={{display: 'flex', flexDirection: 'column', paddingTop: '0'}}>
                            <ListSubheader style={{backgroundColor: '#111', color: '#fff', borderTop:"1px solid #333"}}>All Timesheet Entries</ListSubheader>
                            { filteredReport.map((item, i) => (
                                <ListItem 
                                    key={`${item.client}-${item.date}-${item.hours}-${i}`} 
                                    style={{
                                        display: 'flex', 
                                        flexWrap: 'wrap', 
                                        justifyContent: 'space-between', 
                                        backgroundColor: '#000',
                                        borderBottom: '1px solid #111',
                                    }}>
                                    <Accordion
                                        style={{backgroundColor: '#000', color: '#fff', width: '100%'}}>
                                        <AccordionSummary style={{width: '100%'}}
                                            expandIcon={<ArrowDropDownIcon style={{color: '#fff'}} />}
                                            aria-controls={`panel${i}-content`}
                                            id={`panel${i}-header`}
                                            >
                                            <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between', flexDirection: 'row', width: '100%'}}>
                                                <ListItemIcon>
                                                    <InfoOutlined style={{color: '#fff'}} />
                                                    <div style={{display: 'flex', color: '#fff', paddingLeft: '10px', alignItems: 'center'}}>
                                                        Date: {new Date(item.date).toDateString()}
                                                    </div>
                                                </ListItemIcon>
                                                <div>{item.client}</div>
                                                <div>Hours: {item.hours}</div>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails style={{backgroundColor: '#222', borderRadius: '5px'}}>
                                            <div style={{padding: '5px'}}><b>Date:&nbsp;</b> {item.date}</div>
                                            <div style={{padding: '5px'}}><b>Client:&nbsp;</b> {item.client}</div>
                                            <div style={{padding: '5px'}}><b>Category:&nbsp;</b> {item.category}</div>
                                            <div style={{padding: '5px'}}><b>Hours:&nbsp;</b> { item.hours }</div>
                                            <div style={{padding: '5px'}}><b>Note:&nbsp;</b> { item.note }</div>
                                        </AccordionDetails>
                                    </Accordion>
                                    
                                </ListItem>
                            ))}
                            
                        </List>
                    </Grid>
                </Grid>
            )}
        </div>
    )
}

const ClientReports = ({fromDate, toDate}) => {

    return (
        <div>
            <h2>Client Reports</h2>
        </div>
    )
}