import { Button, Container, List, ListItem, ListSubheader, TextField } from "@mui/material"
import { useContext, useRef, useState } from "react"
import { SnackbarContext } from "../../context/SnackbarContext"
import { Puff } from "react-loading-icons"
import { WSSBufferedContext } from "../../context/NewWSSContext"


export const PasswordCheckPage = () => {
    const [ emailAddress, setEmailAddress ] = useState("")
    const [ passwordResults, setPasswordResults ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const handleChangeEmailAddress = (e) => setEmailAddress(e.target.value)

    const wssC = useContext(WSSBufferedContext)
    const sbC = useRef(useContext(SnackbarContext))
    const searchBreaches = () => {
        // send wss message
        setLoading(true)
        wssC.sendWssMessage({"action":"passwordCheck", "emailAddress": emailAddress}, (res) => {
            setPasswordResults(res.result)
            setLoading(false)
            sbC.current.showMessage("Searched Breaches", "success")
        })
    }

    // enter key
    const onKeyPress = (e) => {
        if (e.keyCode === 13) {
            searchBreaches()
        }
    }

    return (
        <div className="homePageClass">
            <Container>
                <h1 className="userHeader" style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{maxHeight: '25px'}}>
                        { loading && (
                            <Puff />
                        )}
                    </div>
                    Password Check
                </h1>

                <div style={{
                    display: 'flex',
                }}>
                    <TextField 
                        onKeyDown={onKeyPress}
                        sx={{
                            backgroundColor: '#000',
                            color: '#fff',
                            "& .MuiFormLabel-root": {
                                color: '#ccc'
                            }
                        }}
                        inputProps={{
                            style: {
                               color: '#fff'
                            }
                        }}
                        variant="filled"
                        value={emailAddress} onChange={handleChangeEmailAddress} label="Email Address" fullWidth />
                    <Button variant="contained" onClick={searchBreaches}>Search</Button>
                </div>


                <div style={{paddingTop: '25px'}}>
                        <List>
                            <ListSubheader style={{backgroundColor: '#111', color: '#fff'}}>Password Results</ListSubheader>

                            { passwordResults !== null && (
                                <div>
                                { passwordResults.map((item, i) => (
                                    <ListItem key={`${item.line}-${i}`} style={{backgroundColor: '#000', borderBottom: '1px solid #777'}}>
                                        <div>
                                            <div style={{padding: '5px'}}><b>Result:</b>&nbsp;&nbsp;{ item.line.split(":").length === 2 ? (
                                                <>{ item.line.split(":")[0]} : <span style={{color: '#0f0'}}>{item.line.split(":")[1]}</span></>
                                            ) : (
                                                <>{ item.line.split(":")[0] }</>
                                            )}</div>
                                            <div style={{padding: '5px'}}><b>Sources:</b>&nbsp;&nbsp;{ item.sources.join(", ") }</div>
                                            { item.last_breach !== "" && (
                                                <div style={{padding: '5px'}}><b>Last Breach:</b>&nbsp;{item.last_breach}</div>
                                            )}
                                        </div>
                                    </ListItem>
                                ))}
                                </div>
                            )}
                        </List>
                </div>
            </Container>
        </div>
    )
}