import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle,  FormControl, IconButton, InputLabel, List, ListItem, ListItemIcon, MenuItem, Select } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react"
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
//import { WSSContext } from "../../context/WSSContext";
import { Fade } from "react-reveal";
import { WSSBufferedContext } from "../../context/NewWSSContext";
import Puff from "react-loading-icons/dist/esm/components/puff";

export const UserManagePage = () => {
    return (
        <div className="homePageClass">
            <Fade>
                <Container>
                    <h1 className="userHeader">User Management</h1>
                    { /* <InviteUser /> */ }
                    <UserList />

                </Container>
            </Fade>
        </div>
    )
}

/*
const InviteUser = () => {
    const [ userEmail, setUserEmail ] = useState("")
    const handleChangeUserEmail = (e) => setUserEmail(e.target.value)

    const tfStyle = {
        backgroundColor: '#fff',
        margin: '5px',
        borderRadius: '4px'
    }

    const sbC = useContext(SnackbarContext)
    const inviteUser = () => {
        setUserEmail("")
        sbC.showMessage(`Invite User '${userEmail}'.`, "success")
    }

    return (
        <div className="userBackground">
            <h2 style={{margin: "0", padding: "10px"}}>Invite User</h2>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <TextField variant="filled" value={userEmail} onChange={handleChangeUserEmail} label="Email Address" fullWidth style={{...tfStyle}} />
                <Button variant="contained" sx={{marginLeft: '10px'}} onClick={inviteUser}>Invite</Button>
            </div>

        </div>
    )
}
*/

const UserList = () => {
    const [ userList, setUserList ] = useState([])
    
    const wssC = useRef(useContext(WSSBufferedContext))
    useEffect(() => {
        wssC.current.sendWssMessage({action: "adminUsers"}, (res) => {
            setUserList(res)
        })
    }, [])
    

    const [ activeUser, setActiveUser ] = useState({})
    const [ open, setOpen ] = useState(false)
    const handleDialogClose = () => setOpen(false)
    const handleDialogOpen = () => setOpen(true)

    // Dynamic user groups 
    const [ userRole, setUserRole ] = useState("None")
    const handleChangeRole = (e) => setUserRole(e.target.value)
    const [ infoLoaded, setInfoLoaded ] = useState(false)


    const editUserShowDialog = (user) => {
        setInfoLoaded(false)
        setActiveUser(user)
        handleDialogOpen()
    }

    // save 
    const saveUserChanges = () => {
        handleDialogClose()
    }

    return (
        <div>
            <h2>User List</h2>

            { /** Loading of User List */}
            { ((userList === undefined) || (userList.length === 0)) ? (
                <div style={{minHeight: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Puff /></div>
            ) : (
                <List>
                    { userList.map(user => (
                        <ListItem key={user.id} style={{borderBottom: '1px solid #333'}}>
                            <ListItemIcon><PersonIcon style={{color: '#fff'}} /></ListItemIcon>
                            <div style={{flexGrow: '1'}}>{user.userPrincipalName}</div>
                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                <IconButton onClick={() => editUserShowDialog(user)}>
                                    <SettingsIcon style={{color: '#fff'}} />
                                </IconButton>
                            </div>
                        </ListItem>
                    ))}
                </List>
            )}

            <Dialog
                open={open}
                onClose={handleDialogClose}
                >
                <DialogTitle>Edit User</DialogTitle>
                <DialogContent>
                    <div style={{borderBottom: '1px solid #333'}}>
                        <pre>{ JSON.stringify(activeUser, null, 2) }</pre>
                    </div>
                    { infoLoaded ? (
                        <>
                            {/* Application Role Select */}
                            <Fade in={true}>
                                <div style={{paddingTop: '15px'}}>
                                    <h3 style={{textAlign: 'center'}}>Application Access</h3>
                                    <FormControl style={{minWidth: '200px'}}>
                                        <InputLabel id="role-label">User Role</InputLabel>
                                        <Select
                                            value={userRole}
                                            onChange={handleChangeRole}
                                            labelId="role-label"
                                            label="User Role"
                                            >
                                            <MenuItem value="None">None</MenuItem>    
                                            <MenuItem value="User">User</MenuItem>
                                            <MenuItem value="Admin">Admin</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>    
                            </Fade>
                        </>
                    ) : (
                        <div style={{padding: '15px', textAlign: 'center'}}>
                            Todo...
                        </div>
                    )}
                    
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleDialogClose}>Close</Button>
                    <Button variant="contained" color="success" onClick={saveUserChanges}>Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}