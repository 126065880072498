import { Container } from "@mui/material"


export const LogsPage = () => {

    return (
        <div className="homePageClass">
            <Container>
                <h1 className="userHeader">Logs</h1>
            </Container>
        </div>
    )
}