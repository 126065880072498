import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, List, ListItem, ListItemIcon, ListItemText, ListSubheader, MenuItem, Select, TextField } from "@mui/material"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { DatePicker } from "@mui/x-date-pickers"
import { SnackbarContext } from "../../context/SnackbarContext"
import dayjs from "dayjs"
import EditIcon from '@mui/icons-material/Edit';
import { DeleteForever } from "@mui/icons-material"
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ExcelExport from "./ExcelExport"
import { Fade } from "react-reveal"
import { WSSBufferedContext } from "../../context/NewWSSContext"

export const TimesheetPage = () => {
    // OnLoad
    const [ subCategories, setSubcategories ] = useState([])
    const [ localClientIds, setLocalClientIds ] = useState([])

    // Dynamic Below 
    // client 
    const [ selectedClient, setSelectedClient ] = useState("")
    const handleChangeSelectedClient = (e) => setSelectedClient(e.target.value)

    // category
    const [ selectedCategory, setSelectedCategory ] = useState("")
    const handleChangeSelectedCategory = (e) => setSelectedCategory(e.target.value)

    // notes
    const [ note, setNote ] = useState("")
    const handleChangeNote = (e) => setNote(e.target.value)


    useEffect(() => {
        if (selectedClient === "") return 
        const subCats = localClientIds.filter(item => item.clientId === selectedClient)
        
        if (subCats.length > 0) {
            // extract categories
            const titlesOnly = subCats[0].categories.map(item => (item.title))
            setSubcategories(titlesOnly)
        }

    }, [selectedClient, localClientIds])

    const wssC = useContext(WSSBufferedContext)
    const wssRef = useRef(wssC)
    useEffect(() => {
        wssRef.current.sendWssMessage({action: "getClients"}, (res => {
            if (res.length !== 0) {
                const clientData = res.map(item => ({clientId: item.clientid, categories: item.categories}))
                const sortedClients = clientData.sort((a,b) => {
                    if (a.clientId > b.clientId) {
                        return 1
                    } else {
                        return -1 
                    }
                })
                setLocalClientIds(sortedClients)
            }
        }))
    }, [])


    // current date selection
    const curDate = new Date();
    const curDateString = `${curDate.getMonth()+1}/${curDate.getDate()}/${curDate.getFullYear()}`;
    const [ dateSelected, setDateSelected ] = useState(dayjs(curDateString))
    const handleDateChange = (v) => setDateSelected(v)

    // hours selection
    const [ hourSelected, setHourSelected ] = useState(0)
    const handleChangeHour = (e) => setHourSelected(e.target.value)

    // add entry
    const sbC = useContext(SnackbarContext)
    const addTimesheetEntry = () => {
        if (selectedClient === "" || selectedClient === "None") {
            sbC.showMessage("No Client Selected", "warning")
            return 
        } else if (selectedCategory === "" || selectedCategory === "None") {
            sbC.showMessage("No Category Selected", "warning")
            return 
        } else if (note === "") {
            sbC.showMessage("No Note Entered", "warning")
            return 
        } else if (hourSelected === 0) {
            sbC.showMessage("No Hours Entered", "warning")
            return 
        }

        // Get Current Hourly Rate for Category
        const filterSelectedClient = localClientIds.filter(it => it.clientId === selectedClient);
        var hourlyRate = "";
        if (filterSelectedClient.length > 0) {
            const filterSelectedCategory = filterSelectedClient[0].categories.filter(it => it.title === selectedCategory)
            if (filterSelectedCategory.length > 0) {
                hourlyRate = filterSelectedCategory[0].rate;
            }
        }
        const params = {
            client: selectedClient,
            category: selectedCategory,
            note: note,
            hours: hourSelected.toString(),
            date: dateSelected,
            rate: hourlyRate,
        }

        wssC.sendWssMessage({action: "addTimesheetEntry", ...params}, (res) => {
            wssC.sendWssMessage({action:"getTimesheet", date: dateSelected}, (timeSheet) => {
                getSortedEntries(timeSheet)
                sbC.showMessage("Entry Added", "success")
            })
        })
        resetSelected()
    }

    const resetSelected = () => {
        setSelectedClient("")
        setSelectedCategory("")
        setNote("")
        setHourSelected(0)
    }

    const getSortedEntries = useCallback((newEntries) => {
        var ent = newEntries.map(item => ({date: item.date.substr(0,10), item: item}))
        var entireMap = []
        var datesDone = []
        for(let i =0; i < ent.length; i++) {
            if (!datesDone.includes(ent[i].date)) {
                // filter and add
                const items = ent.filter(item => item.date === ent[i].date)

                let totalHours = 0;
                const thMap = items.map(item => item.item.hours)
                thMap.forEach( num => {
                    totalHours += parseFloat(num);
                })

                entireMap.push({date: ent[i].date, entries: items, totalHours: totalHours})
                datesDone.push(ent[i].date)
            }
        }
        setSortedEntries(entireMap)

        // extract for excel building
        const excelD = newEntries.map(item => (
            {
                "Top Level Parent": item.client, 
                "Customer": item.category, 
                "Employee": wssC.reducerState.accountDetails?.name,
                "Date": item.date.substr(0,10),
                "Duration": item.hours,
                "Note": item.note,
            }))
        setExcelData(excelD)
    },[wssC.reducerState.accountDetails])
    
    
    useEffect(() => {
        wssRef.current.sendWssMessage({action:"getTimesheet", date: dateSelected}, (res) => {
            getSortedEntries(res)
        })
    }, [dateSelected, getSortedEntries])

    const [ sortedEntries, setSortedEntries ] = useState([])
    const [ allExcelData, setExcelData ] = useState([])
    


    useEffect(() => {
        wssRef.current.sendWssMessage({action:"getTimesheet", date: dateSelected}, (timeSheet) => {
            getSortedEntries(timeSheet)
        })
    }, [getSortedEntries, dateSelected])

    const updateTimesheet = () => {
        wssC.sendWssMessage({action:"getTimesheet", date: dateSelected}, (timeSheet) => {
            getSortedEntries(timeSheet)
        })
    }

    return (
        <div className="homePageClass" style={{paddingBottom: '30px'}}>
            <Fade>
                <Container>
                    <h1 className="userHeader">Timesheet</h1>
                    <Grid container style={{backgroundColor: '#000', color: '#fff', borderRadius: '10px', border: '1px solid #444', paddingBottom: '20px'}}>
                        <Grid item xs={12} md={12} sx={{padding: '15px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #555', marginBottom: '20px'}}>
                            <h2>Entry</h2>
                            <div>
                                <DatePicker
                                    sx={{backgroundColor: '#fff', borderRadius: '5px'}}
                                    maxDate={dayjs(new Date())}
                                    onChange={handleDateChange}
                                    value={dateSelected}
                                    />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ padding: '15px'}}>
                            <FormControl sx={{ minWidth: 120, maxWidth: '100%', marginBottom: '20px', backgroundColor: '#333', borderRadius: '5px' }} size="small" fullWidth variant="filled">
                                <InputLabel id="selectedclientlabel" style={{color: '#ccc'}}>Client</InputLabel>
                                <Select
                                    sx={{
                                        color: '#fff',
                                        "& .MuiSvgIcon-root": { color: "#fff" }
                                    }}
                                    fullWidth
                                    labelId="selectedclientlabel"
                                    id="selectedclient"
                                    value={selectedClient}
                                    label="Client"
                                    onChange={handleChangeSelectedClient}
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    { localClientIds.map(item => (
                                        <MenuItem key={item.clientId} value={item.clientId}>{item.clientId}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl sx={{ minWidth: 120, maxWidth: '100%', backgroundColor: '#333', borderRadius: '5px'}} fullWidth variant="filled" size="small">
                                <InputLabel id="selectedcatlabel" style={{color: '#ccc'}}>Category</InputLabel>
                                <Select
                                    sx={{
                                        color: '#fff',
                                        "& .MuiSvgIcon-root": { color: "#fff" }
                                    }}
                                    labelId="selectedcatlabel"
                                    id="selectedcategory"
                                    value={selectedCategory}
                                    label="Category"
                                    onChange={handleChangeSelectedCategory}
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    { subCategories.map(item => (
                                        <MenuItem key={item} value={item}>{item}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={4} style={{padding: '15px'}}>
                            <TextField
                                size="small"
                                InputLabelProps={{
                                    sx: {
                                        color: '#ccc'
                                    }
                                }}
                                inputProps={{
                                    sx: {
                                        color: '#fff'
                                    }
                                }}
                                sx={{
                                    backgroundColor: '#333', 
                                    color: '#fff', 
                                    borderRadius: '5px',
                                }}
                                fullWidth
                                id="notes"
                                label="Notes"
                                multiline
                                rows={4}
                                value={note}
                                onChange={handleChangeNote}
                                variant="filled"
                                />
                        </Grid>

                        <Grid item xs={12} md={2} sx={{padding: '15px'}}>
                            <div>
                                { /** Hours Selection */}
                                <FormControl 
                                    sx={{ 
                                        minWidth: 120, maxWidth: '100%', marginBottom: '10px',
                                        borderRadius: '5px', backgroundColor: '#333', color: "#fff",
                                    }} fullWidth size="small" variant="filled" >
                                    <InputLabel id="hourslabel" style={{color: '#ccc'}}>Hours</InputLabel>
                                    <Select 
                                        sx={{
                                            backgroundColor: '#333', 
                                            color: '#fff',
                                            "& .MuiSvgIcon-root": { color: "#fff" },
                                            "& .MuiMenuItem-root:hover": {
                                                backgroundColor: "#333"
                                            },    
                                            "& .MuiMenuItem-root.Mui-selected": {
                                                backgroundColor: "#333"
                                            },
                                        }}
                                        fullWidth
                                        labelId="hourslabel"
                                        id="hours"
                                        value={hourSelected}
                                        label="Hours"
                                        onChange={handleChangeHour}
                                        >
                                        { hoursRanges.map(item => (
                                            <MenuItem key={item} value={item}>{item}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Button 
                                    fullWidth
                                    sx={{minWidth: '100px'}} variant="contained" onClick={addTimesheetEntry}>Save</Button>
                            </div>
                        </Grid>
                    </Grid>

                    { /* Show all time entries below */}
                    <Grid container style={{paddingTop: '20px'}}>
                        <Grid item xs={12} md={12}>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <h2>Entries</h2>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <ExcelExport data={allExcelData} fileName="timesheet" />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                                { sortedEntries.map(item => (
                                    <List key={item.date}>
                                        <ListSubheader style={{
                                            backgroundColor: '#000',
                                            color: '#fff',
                                            border: '1px solid #444'
                                        }}>
                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <div><b>Date:</b> { item.date } </div>
                                                <div><b>Total Hours:</b> {item.totalHours}</div>
                                            </div> 
                                        </ListSubheader>
                                        { item.entries.map(it => (
                                            <ListItem key={it.item.id} style={{borderBottom: '1px solid #444', display: 'flex', flexWrap: 'wrap'}} secondaryAction={
                                                <EditDialog item={it.item} date={dateSelected} updateTimesheet={updateTimesheet}/>
                                            }>
                                                <ListItemIcon sx={{maxWidth: '25px'}}>
                                                    <AccessTimeIcon style={{color: '#fff'}} />
                                                </ListItemIcon>
                                                <ListItemText style={{width: '350px', paddingRight: '10px'}}>{ it.item.category}</ListItemText>
                                                <ListItemText style={{maxWidth: '100px', paddingRight: '10px'}}>Hours: { it.item.hours} </ListItemText>
                                                <ListItemText style={{width: '350px', justifyContent: 'flex-start', display: 'flex', paddingRight: '20px'}}>Note: { it.item.note} </ListItemText>
                                            </ListItem>
                                        ))}
                                    </List>
                                ))}
                        </Grid>
                    </Grid>
                </Container>
            </Fade>
        </div>
    )
}

const EditDialog = ({item, date, updateTimesheet}) => {
    const [ open, setOpen ] = useState(false)
    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)

    const [ hourSelected, setHourSelected ] = useState(item.hours)
    const updateHours = (e) => setHourSelected(e.target.value)
    const [ note, setNote ] = useState(item.note)
    const handleChangeNote = (e) => setNote(e.target.value)

    const wssC = useContext(WSSBufferedContext)

    const sbC = useContext(SnackbarContext)
    const handleSave = () => {
        wssC.sendWssMessage({action: "updateTimesheetEntry", id: item.id, note: note, hours: `${hourSelected}`}, () => {
            updateTimesheet()
            sbC.showMessage("Updated Entry", "success")
        })
        handleDeleteClose()
        handleClose()
    }

    const handleDelete = () => {
        handleDeleteOpen()
    }

    const confirmDelete = () => {
        wssC.sendWssMessage({action: "removeTimesheetEntry", id: item.id}, () => {
            updateTimesheet()
            sbC.showMessage("Deleted Entry", "success")
        })
        handleDeleteClose()
        handleClose()
    }

    // delete dialog options
    const [ deleteOpen, setDeleteOpen ] = useState(false)
    const handleDeleteOpen = () => setDeleteOpen(true)
    const handleDeleteClose = () => setDeleteOpen(false)

    return (
        <div>
            <IconButton onClick={handleOpen}>
                <EditIcon style={{color: '#fff'}} />
            </IconButton>
            <Dialog
                onClose={handleClose}
                open={open}
                >
                <DialogTitle>Edit Entry</DialogTitle>
                <IconButton
                    aria-label="delete"
                    onClick={handleDelete}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                        <DeleteForever style={{color: '#f00'}} />
                    </IconButton>
                <DialogContent>
                    <div style={{display: 'flex', padding: '10px', flexDirection: 'column'}}>
                        <div style={{padding: '5px'}}><b>Client:</b> { item.client}</div>
                        <div style={{padding: '5px'}}><b>Category:</b> { item.category}</div>
                    </div>
                    <div style={{padding: '10px'}}>
                        <FormControl sx={{ minWidth: 120, maxWidth: '100%', marginBottom: '10px'}} fullWidth>
                            <InputLabel id="hourslabel">Hours</InputLabel>
                            <Select
                                fullWidth
                                labelId="hourslabel"
                                id="hours"
                                value={hourSelected}
                                label="Hours"
                                onChange={updateHours}
                                >
                                { hoursRanges.map(item => (
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            fullWidth
                            id="notes"
                            label="Notes"
                            multiline
                            rows={4}
                            value={note}
                            onChange={handleChangeNote}
                            variant="outlined"
                            />
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined">Close</Button>
                    <Button onClick={handleSave} variant="contained" color="success">Save</Button>
                </DialogActions>
            </Dialog>

            { /** Delete Dialog */}
            <Dialog
                onClose={handleDeleteClose}
                open={deleteOpen}
                >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>Are you sure you want to delete the item?</DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteClose} variant="outlined">Close</Button>
                    <Button color="error" variant="contained" onClick={confirmDelete}>Delete</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}



const hoursRanges = [
    0, 0.25, 0.5, 0.75, 1,
    1.25, 1.5, 1.75, 2,
    2.25, 2.5, 2.75, 3,
    3.25, 3.5, 3.75, 4,
    4.25, 4.5, 4.75, 5,
    5.25, 5.5, 5.75, 6,
    6.25, 6.5, 6.75, 7,
    7.25, 7.5, 7.75, 8,
    8.25, 8.5, 8.75, 9,
    9.25, 9.5, 9.75, 10,
    10.25, 10.5, 10.75, 11,
    11.25, 11.5, 11.75, 12,
    12.25, 12.5, 12.75, 13
]