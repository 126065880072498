import { Button, Container, List, ListItem, ListSubheader, TextField } from "@mui/material"
import { useContext, useState } from "react"
import { Puff } from "react-loading-icons"
import { WSSBufferedContext } from "../../context/NewWSSContext"


export const PhoneLookupPage = () => {
    const [ phone, setPhone ] = useState("")
    const [ loading, setLoading ] = useState(false)
    const handleChangePhone = (e) => setPhone(e.target.value)

    const wssC = useContext(WSSBufferedContext)

    const [ phoneResult, setPhoneResult ] = useState(undefined)

    // enter key 
    const onKeyPress = (e) => {
        if (e.keyCode === 13) {
            searchInformation()
        }
    }


    // Search Phone Information 
    const searchInformation = () => {
        setLoading(true)
        console.log("Searching Information")

        // send wss 
        wssC.sendWssMessage({"action": "phoneLookup", "phone": phone}, (res) => {
            console.log("[ phone info received ]")
            setPhoneResult(res)
        })

        // End Result 
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }

    return (
        <div className="homePageClass">
            <Container>
                <h1 className="userHeader" style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{maxHeight: '25px'}}>
                        { loading && (
                            <Puff />
                        )}
                    </div>
                    Phone Lookup
                </h1>

                <div style={{display: 'flex'}}>
                    <TextField
                        onKeyDown={onKeyPress}
                        fullWidth
                        variant="filled"
                        sx={{
                            backgroundColor: '#000',
                            color: '#fff',
                            "& .MuiFormLabel-root": {
                                color: '#ccc'
                            }
                        }}
                        inputProps={{
                            style: {
                                color: '#fff'
                            }
                        }}
                        value={phone} 
                        label="Phone Number" 
                        onChange={handleChangePhone}  />
                        <Button variant="contained" onClick={searchInformation}>Search</Button>
                </div>

                <div style={{padding: '10px 5px 5px 5px', fontSize: '0.8rem'}}>
                    * Please use the format +1XXXYYYZZZZ for input
                </div>

                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <a style={{textDecoration: 'none'}} href="https://www.mcc-mnc.com/" target="_blank" rel="noreferrer">
                        <Button variant="contained" color="info">Network Codes</Button>
                    </a>
                </div>

                <div style={{
                    marginTop: '25px',
                    borderRadius: '10px',
                    backgroundColor: '#000',
                    padding: '15px',
                    minHeight: '250px'
                }}>
                    { phoneResult !== undefined ? (
                        <div>
                            <List>
                                <ListSubheader style={{backgroundColor: '#111', color: '#fff', border: '1px solid #444'}}>Phone Number Information</ListSubheader>
                                <ListItem><b>International Format:&nbsp;</b>{ phoneResult.internationalFormat }</ListItem>
                                <ListItem><b>ISO Country Code:&nbsp;</b>{ phoneResult.isoCountryCode }</ListItem>
                                <ListItem><b>National Format:&nbsp;</b>{ phoneResult.nationalFormat }</ListItem>
                                <ListItem><b>Number Type:&nbsp;</b>{ phoneResult.numberType }</ListItem>
                                <ListItem><b>Phone Number:&nbsp;</b>{ phoneResult.phoneNumber }</ListItem>

                                <ListSubheader style={{marginTop: '15px', backgroundColor: '#111', color: '#fff', border: '1px solid #444'}}>Operator Details</ListSubheader>
                                <ListItem><b>Mobile Country Code:&nbsp;</b>{ phoneResult.operatorDetails?.mobileCountryCode }</ListItem>
                                <ListItem><b>Mobile Network Code:&nbsp;</b>{ phoneResult.operatorDetails?.mobileNetworkCode }</ListItem>
                                <ListItem><b>Name:&nbsp;</b>{ phoneResult.operatorDetails?.name }</ListItem>

                            </List>

                        </div>
                    ) : (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '250px'}}>
                            Search a number for details.
                        </div>
                    )}
                </div>
            </Container>
        </div>
    )
}