import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemButton, ListSubheader } from '@mui/material';
import { AppConstants } from '../constants/AppConstants';
import { LoginButton, LogoutButton, RenderAdmin, RenderUser } from '../context/NewLoginContext';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { WSSBufferedContext } from '../context/NewWSSContext';

export const NavbarComponent = () => {
    const [anchorElNav, setAnchorElNav] = useState(null);
  
    const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
    };
  
    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };
  
    //const mainC = useContext(MainContext)
    //const wssC = useContext(WSSContext)
    const wssNewC = useContext(WSSBufferedContext)

    return (
        <AppBar position="static" className='appBarClass'>
        <Container maxWidth="xl">
          <Toolbar disableGutters>

            { /* Medium Screen Display */ }
            <Typography
              variant="h6"
              noWrap
              component={Link}
              to="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.1rem',
                color: 'inherit',
                textDecoration: 'none',
                
              }}
            >
              <img src={process.env.PUBLIC_URL + '/images/nsg-logo.webp'} style={{height: '60px'}} alt="NSG"/>
            </Typography>
  
            { /* Small Screen Display */ }
            <Typography
              variant="h5"
              noWrap
              component={Link}
              to="/"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '0rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              <img src={process.env.PUBLIC_URL + '/images/nsg-logo.webp'} style={{height: '50px'}} alt="NSG"/>
            </Typography>

            { /* Medium Screen Display */}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>              
                <div>
                  <Button className='navbarItem' sx={{ my: 2, color: 'white' }} 
                    LinkComponent={Link}
                    to={"/"}
                    key={"dashboard"}
                    onClick={handleCloseNavMenu}
                    startIcon={<DashboardIcon />}>
                    Dashboard
                  </Button>
                </div>
                <div>
                  <Button className='navbarItem' sx={{ my: 2, color: 'white' }} onClick={handleOpenNavMenu} startIcon={<MenuIcon />}>
                    Menu
                  </Button>
                </div>
            </Box>

            { /* Drawer Navigation */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end' }}>
                <IconButton
                    size="large"
                    aria-label="menu"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                >
                    <MenuIcon />
                </IconButton>

                <Drawer 
                    PaperProps={{style: {
                      backgroundColor: '#000',
                      color: "#fff",
                      borderLeft: ' 1px solid #333',
                    }}}
                    anchor='right'
                    open={Boolean(anchorElNav)} 
                    onClose={handleCloseNavMenu}>
                    <List className='drawerStyle'>
                        { /* Base Page Links */}
                        <ListSubheader className='drawerSubheader'>{ AppConstants.AppName }</ListSubheader>
                          <ListItem className='drawerItem' key={"dashboard"}>
                              <ListItemButton LinkComponent={Link} to={"/"} onClick={handleCloseNavMenu}>
                                  <DashboardIcon />&nbsp;&nbsp;
                                  Dashboard
                              </ListItemButton>
                          </ListItem>

                        { /* User Links */}
                        <RenderUser>
                          <ListSubheader className='drawerSubheader'>Navigation</ListSubheader>
                          {AppConstants.UserLinks.map((page) => (
                            <ListItem className='drawerItem' key={page.title}>

                              { !page.external ? (
                                <ListItemButton LinkComponent={Link} to={page.href} onClick={handleCloseNavMenu}>
                                  { page.icon }&nbsp;&nbsp;
                                  {page.title}
                                </ListItemButton>
                              ) : (
                                <a href={page.href} target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: 'inherit', display: 'flex', flexGrow: '1'}}>
                                  <ListItemButton onClick={handleCloseNavMenu}>
                                    { page.icon }&nbsp;&nbsp;
                                    {page.title}
                                  </ListItemButton>
                                </a>
                              )}

                              
                            </ListItem>
                          ))}
                        </RenderUser>

                        <RenderUser>
                          <ListSubheader className='drawerSubheader'>Tools</ListSubheader>
                          {AppConstants.UserToolLinks.map((page) => (
                            <ListItem className='drawerItem' key={page.title}>
                              <ListItemButton LinkComponent={Link} to={page.href} onClick={handleCloseNavMenu}>
                                {page.icon}&nbsp;&nbsp;
                                {page.title}
                              </ListItemButton>
                            </ListItem>
                          ))}


                          { /* Admin Capable Tools */}
                          <RenderAdmin>
                            {AppConstants.AdminToolLinks.map((page) => (
                              <ListItem className='drawerItem' key={page.title}>
                                <ListItemButton LinkComponent={Link} to={page.href} onClick={handleCloseNavMenu}>
                                  {page.icon}&nbsp;&nbsp;
                                  {page.title}
                                </ListItemButton>
                              </ListItem>
                            ))}
                          </RenderAdmin>
                        </RenderUser>

                        { /* Admin Capable Mobile */}
                        <RenderAdmin>
                          <ListSubheader className='drawerSubheader'>Admin</ListSubheader>
                          {AppConstants.AdminPageLinks.map((page) => (
                            <ListItem className='drawerItemAdmin' key={page.title}>
                              <ListItemButton LinkComponent={Link} to={page.href} onClick={handleCloseNavMenu}>
                                {page.icon}&nbsp;&nbsp;
                                {page.title}
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </RenderAdmin>

                        { /* Login & Logout Buttons */}
                        <ListSubheader className='drawerSubheader'>Profile</ListSubheader>
                        { wssNewC.isAuthenticated ? (
                            <LogoutButton listItem={true} closeCb={handleCloseNavMenu} />
                        ) : (
                            <LoginButton listItem={true} closeCb={handleCloseNavMenu} />
                        )}
                    </List>
                </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    )
}